import { useState } from "react";
import { Stack, Typography, Divider, CircularProgress, Fade } from "@mui/material";
import { useAppDispatch, useAppSelector } from "helpers/hooks";
import { useEffect, useMemo } from "react";
import { getCompanyInfo, resetUpdateCompany, resetLogoUpdate } from "store/slices/employer/settingsTs/company-info-slice";
import { format } from "date-fns";
import StarIcon from "@mui/icons-material/Star";
import { ShChip } from "shared/SharedStyles/ShDataDisplay";
import { ShPaper } from "shared/SharedStyles/ShSurfaces";
import { ShButton } from "shared/SharedStyles/ShInputs";
import { allSubscriptionBundles } from "./SubscriptionsConstants";
import { cancelSubscription } from "store/slices/employer/create-job/create-job-payments-slice";
import { Link as RouterLink } from 'react-router-dom';
import { ConfirmationDialog } from "shared/SharedComponents/ConfirmationDialog/ConfirmationDialog";

export const ManageSubscriptions = () => {
    const dispatch = useAppDispatch();
    const { companyInfo } = useAppSelector((state) => state.employer.employerSettings.companyInfo);
    const { purchaseJobStatus, purchaseJobResponse } = useAppSelector((state) => state.employer.createJob.createJobPayments);
    const [isDialogOpen, setIsDialogOpen] = useState(false);

    useEffect(() => {
        if (!companyInfo.id) {
            dispatch(getCompanyInfo());
        }

        return () => {
            dispatch(resetUpdateCompany());
            dispatch(resetLogoUpdate());
        };
    }, [companyInfo, dispatch]);
  
    const currentPlan = useMemo(() => {
        if (companyInfo.subscription_plan) {
            const hyphenCount = (companyInfo.subscription_plan.match(/-/g) || []).length;
            if (hyphenCount === 2) {
                const lastHyphenIndex = companyInfo.subscription_plan.lastIndexOf('-');
                const planName = companyInfo.subscription_plan.slice(0, lastHyphenIndex);
                const isYearly = companyInfo.subscription_plan.slice(lastHyphenIndex + 1) === "yearly";
    
                const bundle = allSubscriptionBundles.find((bundle) => bundle.code === planName);
    
                return {
                    bundle,
                    isYearly,
                };
            }else{
                const bundle = allSubscriptionBundles.find((bundle) => bundle.code === companyInfo.subscription_plan);
                return {
                    bundle,
                    isYearly:false,
                };
            }
        }
    
        return { bundle: null, isYearly: false };
    }, [companyInfo]);
    
    


    const handleCloseDialog = () => {
        setIsDialogOpen(false);
    };

    const handleCancelSubscription = async () => {
        setIsDialogOpen(false); 
        if (companyInfo?.stripe_subscription_id) {
            await dispatch(cancelSubscription({ stripeSubscriptionId: companyInfo.stripe_subscription_id }));
            dispatch(getCompanyInfo());
        }
    };

    return (
        <Fade in={true} timeout={800}>
            <Stack spacing={1} alignItems='center' direction='row'>
                <ShPaper variant="outlined">
                    <Stack>
                        <Typography variant="h6" fontWeight="bold">
                            Current Plan
                        </Typography>

                        <Stack direction="row" alignItems="center" spacing={1} mt={1}>
                            <ShChip
                                size="small"
                                label={companyInfo.subscription_plan ? currentPlan.bundle?.name + " Plan" : "No Plan"}
                                color="primary"
                                textColor="white"
                                borderRadius="10px"
                                icon={<StarIcon color="primary" />}
                            />
                        </Stack>

                        <Typography variant="body2" color="text.secondary" mt={1}>
                            Member since {companyInfo.createdAt ? format(new Date(companyInfo.createdAt), "MMM yyyy") : "N/A"}
                        </Typography>

                        {companyInfo.subscription_plan && (
                            <>
                                <Typography variant="body2" color="text.secondary" mt={2}>
                                    <strong>Next Payment</strong>
                                </Typography>

                                <Typography variant="h6">
                                    {companyInfo.renewal_date ? format(new Date(companyInfo.renewal_date), "MMMM d, yyyy") : "N/A"}
                                </Typography>

                                <Typography variant="h4" fontWeight="bold" mt={2}>
                                {currentPlan.bundle
                                    ? `$${currentPlan.isYearly ? currentPlan.bundle.yearlyPrice : currentPlan.bundle.base_price}`
                                : "N/A"} 
                                    <Typography variant="body1" component="span">
                                        / {currentPlan.isYearly ? "year" : "month"}
                                    </Typography>
                                </Typography>
                            </>
                        )}
                        <Stack direction="row" justifyContent="space-between" mt={3}>
                            <ShButton color="primary" component={RouterLink} to={`/employer/settings/account/subscriptions#billing-payment-history`}>
                                View Invoices
                            </ShButton>
                        </Stack>
                        <Divider sx={{ my: 2 }} />
                        <Stack direction="row" justifyContent="space-between" spacing={6}>
                            {companyInfo?.stripe_subscription_id ? (
                                <ShButton
                                    variant="outlined"
                                    color="error"
                                    onClick={()=> setIsDialogOpen(true)} // Open confirmation dialog
                                    disabled={purchaseJobStatus === "pending"}
                                >
                                    {purchaseJobStatus === "pending" ? (
                                        <>
                                            <CircularProgress size={20} sx={{ mr: 1 }} /> Canceling...
                                        </>
                                    ) : (
                                        "Cancel Subscription"
                                    )}
                                </ShButton>
                            ) : (
                                <Typography variant="body2" color="text.secondary">
                                    No active subscription to cancel.
                                </Typography>
                            )}

                            <ShButton variant="contained" color="primary" component={RouterLink} to={`/employer/settings/account/subscriptions#view-subscriptions`}>
                                Upgrade Subscription
                            </ShButton>
                        </Stack>

                        {purchaseJobResponse && (
                            <Typography variant="body2" color={purchaseJobStatus === "failed" ? "error" : "success"} mt={2}>
                                {purchaseJobResponse}
                            </Typography>
                        )}
                    </Stack>
                </ShPaper>

                <ConfirmationDialog
                    title="Cancel Subscription"
                    contentText="Are you sure you want to cancel your subscription? You may lose access to premium features."
                    isDialogOpen={isDialogOpen}
                    onCancel={handleCloseDialog}
                    onConfirm={handleCancelSubscription}
                    cancelButtonLabel="No, Keep It"
                    confirmButtonLabel="Yes, Cancel"
                    isShowCloseIcon={true}
                />
            </Stack>
        </Fade>
    );
};

export default ManageSubscriptions;
