import { useAppDispatch, useAppSelector } from 'helpers/hooks';
import { useEffect, useState, useRef } from 'react';
import {
  RadioGroup,
  FormControlLabel,
  Radio,
  MenuItem,
  FormControl,
  FormHelperText,
  Typography,
  Box,
  Stack,
  LinearProgress,
  CircularProgress,
} from '@mui/material';
import dayjs, { Dayjs } from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { ShResizableTextField, ShTextFieldV2, ShDatePickerV2, ShInputBase } from 'shared/SharedStyles/ShInputs';
import { ShPaper } from 'shared/SharedStyles/ShSurfaces';
import {
  fetchCandidateAdditionalQuestionsAnswers,
  updateCandidateAdditionalQuestionsJobApply,
  uploadAdditionalCandidateDocuments,
} from 'store/slices/candidate/apply-job/apply-job-questionnaire.slice';
import { ApplyJobAdditionalQuestionsProps } from '../CreateJob/EEO/EEOConfig';
import { AdditionalQuestionAnswer } from './ApplyJobModel';

export const ApplyJobAdditionalQuestions = ({selectedJobForm,onFormValidation}: ApplyJobAdditionalQuestionsProps) => {
  const dispatch = useAppDispatch();
  const { candidateDetails } = useAppSelector((state) => state.candidate.candidateProfile);
  const { additionalQuestions, fetchAdditionalQuestionsStatus, updateCandidateDocumentsStatus } =
    useAppSelector((state) => state.candidate.applyJobQuestionnaire);
  const [answers, setAnswers] = useState<{[questionId: string]: AdditionalQuestionAnswer}>({});
  const [isAdditionalQuestionsAnswered, setIsAdditionalQuestionsAnswered] = useState(false);
  const fileInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (selectedJobForm && additionalQuestions) {
      validateForm();
    }
  }, [answers, selectedJobForm]);

  const validateForm = () => {
    if (selectedJobForm) {
      const allAnswered = selectedJobForm.questions.every((question) => {
        if (question.required === 'required') {
          return (
            answers[question.id]?.answer !== undefined &&
            answers[question.id]?.answer !== ''
          );
        }
        return true;
      });

      onFormValidation(allAnswered);
    }
  };

  useEffect(() => {
    if (candidateDetails?.jobApplicationId) {
      dispatch(
        fetchCandidateAdditionalQuestionsAnswers({
          jobApplicationId: candidateDetails.jobApplicationId,
        })
      );
    }
  }, [dispatch, candidateDetails?.jobApplicationId]);


  useEffect(() => {
    // Mapping the incoming data to the answers state, including 'autoReject' for each option
    if (additionalQuestions && Object.keys(additionalQuestions).length > 0) {
      const initialAnswers = Object.entries(additionalQuestions).reduce(
        (
          acc: { [key: string]: AdditionalQuestionAnswer },
          [questionId, { answer, autoReject }]
        ) => {
          acc[questionId] = { answer, autoReject };
          return acc;
        },
        {}
      );
      setAnswers(initialAnswers);
    }
  }, [additionalQuestions]);

  const handleChange = async (
    questionId: string,
    value: string,
    autoReject: boolean
  ) => {
    setIsAdditionalQuestionsAnswered(true);
    setAnswers((prev) => ({
      ...prev,
      [questionId]: { answer: value, autoReject },
    }));
  };

  useEffect(() => {
    let debouncing: NodeJS.Timeout;
    const updatedQAdditionalQuestions = async () => {
      const updatedAnswers = Object.entries(answers).map(
        ([questionId, { answer, autoReject }]) => ({
          questionId,
          answer,
          autoReject,
        })
      );

      await dispatch(
        updateCandidateAdditionalQuestionsJobApply({
          jobApplicationId: candidateDetails?.jobApplicationId!,
          additionalQuestions: updatedAnswers,
        })
      );
    };

    if (candidateDetails?.jobApplicationId && isAdditionalQuestionsAnswered) {
      debouncing = setTimeout(() => {
        updatedQAdditionalQuestions();
      }, 500);
    }

    return () => {
      if (debouncing) {
        clearTimeout(debouncing);
      }
    };
  }, [
    answers,
    candidateDetails?.jobApplicationId,
    isAdditionalQuestionsAnswered,
    dispatch,
  ]);

  const handleFileChange = (questionId: string, event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0]; 
    if (file) {
      if(candidateDetails?.jobApplicationId){
      const body = new FormData();
      body.append("additional_document", file);
      body.append("questionId", questionId); 
      body.append("jobApplicationId", candidateDetails.jobApplicationId.toString());

    
        dispatch(uploadAdditionalCandidateDocuments({
          payload: body,
          questionId: questionId,
          jobApplicationId: candidateDetails.jobApplicationId.toString()
        }));
        
      }
    }
  };

  return (
    <ShPaper variant="outlined">
      {selectedJobForm ? (
        <Stack>
          {selectedJobForm.questions.map((question) => (
            <Box key={question.id} sx={{ marginBottom: 3 }}>
              <Typography variant="body2">
                {question.question}
                {question.required === 'required' && (
                  <span style={{ color: 'red' }}> *</span>
                )}
              </Typography>

              {/* Textfield question type */}
              {question.type === 'textfield' && (
                <ShTextFieldV2
                  size="small"
                  fullWidth
                  variant="outlined"
                  margin="normal"
                  placeholder="Your answer"
                  required={question.required === 'required'}
                  value={answers[question.id]?.answer || ''}
                  onChange={(e) =>
                    handleChange(question.id, e.target.value, false)
                  } // no autoReject for text field
                />
              )}

              {/* Radio buttons question type */}
              {(question.type === 'radio' ||  question.type === 'yesNo') && (
                <RadioGroup
                  value={answers[question.id]?.answer || ''}
                  onChange={(e) => {
                    const selectedOption = question.options.find(
                      (option) => option.label === e.target.value
                    );
                    handleChange(
                      question.id,
                      e.target.value,
                      selectedOption?.autoReject || false
                    );
                  }}
                >
                  {question.options.map((option) => (
                    <FormControlLabel
                      key={option.label}
                      control={<Radio />}
                      label={option.label}
                      value={option.label}
                    />
                  ))}
                </RadioGroup>
              )}

              {/* Dropdown question type */}
              {question.type === 'dropdown' && (
                <FormControl fullWidth variant="outlined" margin="normal">
                  <ShTextFieldV2
                    select
                    fullWidth
                    size="small"
                    value={answers[question.id]?.answer || ''}
                    onChange={(e) => {
                      const selectedOption = question.options.find(
                        (option) => option.label === e.target.value
                      );
                      handleChange(
                        question.id,
                        e.target.value,
                        selectedOption?.autoReject || false
                      );
                    }}
                    required={question.required === 'required'}
                  >
                    {question.options.map((option) => (
                      <MenuItem key={option.label} value={option.label}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </ShTextFieldV2>
                  <FormHelperText>
                    {question.required === 'required' ? 'Required' : 'Optional'}
                  </FormHelperText>
                </FormControl>
              )}

              {/* Paragraph question type */}
              {question.type === 'textarea' && (
                <Stack marginTop={2} marginBottom={1}>
                  <ShResizableTextField
                    onClick={(e) => e.stopPropagation()}
                    multiline
                    rows={3}
                    margin="dense"
                    id="paragraph"
                    label="Your answer"
                    fullWidth
                    variant="outlined"
                    value={answers[question.id]?.answer || ''}
                    onChange={(e) =>
                      handleChange(question.id, e.target.value, false)
                    }
                  />
                </Stack>
              )}

              {/* Date question type */}
              {question.type === 'date' && (
                <Stack marginTop={2} marginBottom={1}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <ShDatePickerV2
                      format="MM/DD/YYYY"
                      slotProps={{
                        textField: {
                          size: "small",
                          fullWidth: true,
                          placeholder: "MM/DD/YYYY",
                          variant: "outlined",
                        },
                      }}
                      value={
                        answers[question.id]?.answer
                          ? dayjs(answers[question.id]?.answer)
                          : null
                      }
                      onChange={(newValue) => {
                        const selectedDate = newValue as Dayjs | null; // Explicit cast
                        handleChange(
                          question.id,
                          selectedDate ? selectedDate.format("MM/DD/YYYY") : "",
                          false
                        );
                      }}
                    />
                  </LocalizationProvider>
                </Stack>
              )}

              {/* Date question type accepting today and future date */}
              {question.type === 'noOldDate' && (
                <Stack marginTop={2} marginBottom={1}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <ShDatePickerV2
                      format="MM/DD/YYYY"
                      shouldDisableDate={(date) => {
                        const day = dayjs(date as Dayjs); // Explicitly cast date as Dayjs
                        return day.isBefore(dayjs(), 'day'); // Disable past dates
                      }}
                      slotProps={{
                        textField: {
                          size: "small",
                          fullWidth: true,
                          placeholder: "MM/DD/YYYY",
                          variant: "outlined",
                        },
                      }}
                      value={
                        answers[question.id]?.answer
                          ? dayjs(answers[question.id]?.answer)
                          : null
                      }
                      onChange={(newValue) => {
                        const selectedDate = newValue as Dayjs | null; // Explicit cast
                        handleChange(
                          question.id,
                          selectedDate ? selectedDate.format("MM/DD/YYYY") : "",
                          false
                        );
                      }}
                    />
                  </LocalizationProvider>
                </Stack>
              )}

              {/* File Upload Question Type */}
              {question.type === "fileUpload" && (
              <Stack direction="row" spacing={1}>
                {updateCandidateDocumentsStatus  === 'pending' && <CircularProgress color="primary" /> }
                <FormControl component="fieldset" size="small">
                  <ShInputBase type="file" inputRef={fileInputRef} onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleFileChange(question.id, event)} />
                  {fileInputRef.current?.files?.[0] ? (
                    <Typography gutterBottom>
                      Current File: {fileInputRef.current.files[0].name}
                    </Typography>
                  ) : answers[question.id]?.answer ? (
                    <Typography>
                      Current File: {answers[question.id]?.answer.includes('~') ? answers[question.id]?.answer.split('~')[1] : answers[question.id]?.answer}
                    </Typography>
                  ) : null}
                </FormControl>      
              </Stack>
              )}

              {/* number question type */}
              {question.type === 'number' && (
                <Stack marginTop={2} marginBottom={1}>
                  <ShTextFieldV2
                    type="number"
                    size="small"
                    fullWidth
                    variant="outlined"
                    placeholder="Enter a number"
                    inputProps={{
                      inputMode: "numeric",
                      pattern: "[0-9]*",
                    }}
                    value={answers[question.id]?.answer || ""}
                    onChange={(event) => {
                      const inputValue = event.target.value;
                      handleChange(question.id, inputValue, false);
                    }}
                  />
                </Stack>
              )}
            </Box>
          ))}
        </Stack>
      ) : fetchAdditionalQuestionsStatus === 'pending' ? (
        <Stack direction="row" spacing={1}>
          <LinearProgress />
          <Typography variant="body1" color="textSecondary">
            Loading form questions...
          </Typography>
        </Stack>
      ) : (
        <Typography variant="body1" color="error.main">
          Failed to load additional questions.
        </Typography>
      )}
    </ShPaper>
  );
};
