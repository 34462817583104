import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    ListItemIcon,
    ListItemText,
    MenuItem,
    Pagination,
    Stack,
    TextField,
    Typography,
} from "@mui/material";
import Menu from '@mui/material/Menu';
import { IApplicantsFooter } from 'Modules/Core/Applicants/ApplicantsModel';
import { useNotification } from 'Modules/Core/Notification';
import { CSVFileIcon, EmailShareIcon, ExcelFileIcon } from 'assets/Icons';
import { IsXsScreen, useAppDispatch, useAppSelector } from 'helpers/hooks';
import { ChangeEvent, useCallback, useEffect, useState } from "react";
import { Link as RouterLink } from 'react-router-dom';
import { ApiState, LayoutTypes } from 'shared/SharedModels';
import { ShButton } from 'shared/SharedStyles/ShInputs';
import { FooterWrapper } from 'shared/SharedStyles/ShLayouts';
import { StyledMenuItem } from 'shared/SharedStyles/ShNavigation';
import { EmailRegEx, PageSizes } from 'shared/constants';
import { downloadApplicantsAsCSV, downloadApplicantsAsExcel, resetDownloadCSV, resetDownloadExcel, resetShareToEmail, shareApplicantsToEmail } from 'store/slices/employer/applicants/applicants-list-actions-slice';

export const ApplicantsFooter = ({ paginationParams, getApplicants, isSmScreen, jobId, selectedApplicants,
    layoutType, countInThisPage }: IApplicantsFooter) => {
    const dispatch = useAppDispatch();
    const notification = useNotification();
    const isXsScreen = IsXsScreen();
    const { shareApplicantsStatus, shareApplicantsResponse, downloadAsCSVStatus, downloadAsExcelStatus,
        downloadAsCSVResponse, downloadAsExcelResponse
    } = useAppSelector((state) => state.employer.applicants.applicantsListActions);
    const { isAtsPurchased } = useAppSelector((state) => state.employer.applicants.applicantsList);
    const [pgntnAnchorEl, setPgntnAnchorEl] = useState<HTMLElement | null>(null);
    const isPageSizeOpen = Boolean(pgntnAnchorEl);
    const [moreActionsMenuEl, setMoreActionsMenuEl] = useState<HTMLElement | null>(null);
    const isMoreActionsOpen = Boolean(moreActionsMenuEl);
    const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
    const [shareEmail, setShareEmail] = useState<string>("");
    const [isEmailInValid, setIsEmailInValid] = useState<boolean>(false);

    // Reused notification function to show notifications and dispatch reset actions in slice. 
    const showNotification = useCallback((apiStatus: ApiState, resetFunc: Function, responseMsg?: string) => {
        notification.displayNotification({
            open: true,
            type: apiStatus === 'failed' ? 'error' : 'success',
            message: responseMsg ?? ''
        });
        if (apiStatus === 'success') {
            setIsDialogOpen(false);
        }
        dispatch(resetFunc());
    }, [dispatch, notification]);

    const validateEmail = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement> | undefined) => {
        const reg = new RegExp(EmailRegEx);
        setIsEmailInValid(!reg.test(e?.target.value ?? ''));
        setShareEmail(e?.target.value ?? '');
    };

    const shareToEmail = (email: string) => {
        let selectedIds = selectedApplicants?.join(',');
        if (selectedApplicants?.length === countInThisPage) {
            selectedIds = '';
        }
        dispatch(shareApplicantsToEmail({ toEmail: email, employerJobId: jobId, applicantIds: selectedIds }));
    };

    // Show snackbar notifications when action is either successful or failed.
    useEffect(() => {
        if (shareApplicantsStatus === 'failed' || shareApplicantsStatus === 'success') {
            showNotification(shareApplicantsStatus, resetShareToEmail, shareApplicantsResponse);
        }
    }, [shareApplicantsResponse, shareApplicantsStatus, showNotification]);

    // Show notification if export is failed, Close menu if successful
    useEffect(() => {
        if (downloadAsCSVStatus === 'failed') {
            showNotification(downloadAsCSVStatus, resetDownloadCSV, downloadAsCSVResponse);
        } else if (downloadAsCSVStatus === 'success') {
            setMoreActionsMenuEl(null);
            dispatch(resetDownloadCSV());
        }
    }, [dispatch, downloadAsCSVResponse, downloadAsCSVStatus, showNotification]);

    useEffect(() => {
        if (downloadAsExcelStatus === 'failed') {
            showNotification(downloadAsExcelStatus, resetDownloadExcel, downloadAsExcelResponse);
        } else if (downloadAsExcelStatus === 'success') {
            setMoreActionsMenuEl(null);
            dispatch(resetDownloadExcel());
        }
    }, [dispatch, downloadAsExcelResponse, downloadAsExcelStatus, showNotification]);

    return (<>
        {isXsScreen && !isAtsPurchased && <ShButton component={RouterLink} to={`/employer/incomplete-applicants/${jobId}`} fullWidth>
            <Typography variant='body2'>Incomplete Applicants</Typography>
        </ShButton>}
        <FooterWrapper>
            {/* Temporarily commenting. Below empty box is to be removed after enabling this*/}
            {/* <Box></Box> */}
            <Stack direction='row' columnGap={1}>
                <ShButton startIcon={<ExpandLessIcon />} aria-label='More Actions' color='primary' size='small'
                    disableElevation variant='contained' onClick={e => setMoreActionsMenuEl(e.currentTarget)}>More</ShButton>
                {layoutType === LayoutTypes.List && !isXsScreen && !isAtsPurchased &&
                    <ShButton component={RouterLink} to={`/employer/incomplete-applicants/${jobId}`}>
                        <Typography variant='body2'>Incomplete Applicants</Typography>
                    </ShButton>}
            </Stack>

            <Menu id="more-actions-menu" aria-label='More actions menu' anchorEl={moreActionsMenuEl}
                open={isMoreActionsOpen} onClose={() => setMoreActionsMenuEl(null)}>
                {/* Disable option while api is fetching data and display loading label. */}
                <MenuItem onClick={() => dispatch(downloadApplicantsAsExcel({ jobId: jobId }))}
                    disabled={downloadAsExcelStatus === 'pending'}>
                    <ListItemIcon><ExcelFileIcon /></ListItemIcon>
                    <ListItemText>{downloadAsExcelStatus === 'pending' ? 'Downloading...' : 'Export as Excel'}</ListItemText>
                </MenuItem>
                <MenuItem onClick={() => dispatch(downloadApplicantsAsCSV({ employerJobId: jobId }))}
                    disabled={downloadAsCSVStatus === 'pending'}>
                    <ListItemIcon><CSVFileIcon /></ListItemIcon>
                    <ListItemText>{downloadAsCSVStatus === 'pending' ? 'Downloading...' : 'Export as CSV'}</ListItemText>
                </MenuItem>
                <MenuItem onClick={() => { setMoreActionsMenuEl(null); setIsDialogOpen(true); }}>
                    <ListItemIcon><EmailShareIcon /></ListItemIcon>
                    <ListItemText>Email</ListItemText>
                </MenuItem>
            </Menu>

            <Stack flexDirection='row' justifyContent='flex-end' alignItems='center' columnGap={{ xs: 0, sm: 1, md: 1 }} flexWrap='wrap'>
                {/* Number of records in a given page and total records information. */}
                <Typography paddingLeft={3} variant='caption' fontWeight='bold'>
                    {((paginationParams.requestedPageNumber - 1) * paginationParams.requestedPageSize) + paginationParams.count}&nbsp;of&nbsp;{paginationParams.totalElements}
                </Typography>
                {/* MUI pagination. Changes sibling and boundary counts based on screen size to best fit the page numbers in smaller screens. */}
                <Pagination className='desktop-pagination' color="primary" showFirstButton showLastButton
                    siblingCount={isSmScreen ? 0 : 0} boundaryCount={isSmScreen ? 0 : 2}
                    count={paginationParams.totalPages} page={paginationParams.requestedPageNumber}
                    onChange={(e, pgNo) => getApplicants(pgNo, paginationParams.requestedPageSize)}
                />
                {/* MUI pagination is replaced with simple prev and next stack of buttons for mobile screens. */}
                <Stack className='mobile-pagination' flexDirection='row'>
                    <IconButton disabled={paginationParams.firstPage}
                        onClick={() => getApplicants(paginationParams.requestedPageNumber - 1, paginationParams.requestedPageSize)}>
                        <KeyboardArrowLeftIcon />
                    </IconButton>
                    <IconButton disabled={paginationParams.lastPage}
                        onClick={() => getApplicants(paginationParams.requestedPageNumber + 1, paginationParams.requestedPageSize)}>
                        <KeyboardArrowRightIcon />
                    </IconButton>
                </Stack>
                {/* Page size select menu. */}
                <ShButton size='small' variant='contained' disableElevation onClick={e => setPgntnAnchorEl(e.currentTarget)}
                    color='primary' endIcon={<ExpandMoreIcon />}>{paginationParams.requestedPageSize}</ShButton>
                <Menu id="page-size-menu" aria-label='Page size' anchorEl={pgntnAnchorEl}
                    open={isPageSizeOpen} onClose={() => setPgntnAnchorEl(null)}>
                    {PageSizes.map(size => (
                        <StyledMenuItem key={size} className={paginationParams.requestedPageSize === size ? "selected-item" : ""}
                            onClick={() => {
                                getApplicants(1, size);
                                setPgntnAnchorEl(null);
                            }}>{size}</StyledMenuItem>
                    ))}
                </Menu>
            </Stack>
        </FooterWrapper>
        {/* Share to Email Dialog */}
        <Dialog open={isDialogOpen} onClose={() => setIsDialogOpen(false)} aria-labelledby="title" aria-describedby="subtext">
        <DialogTitle id='title' fontWeight='bold'>Email  Applicant(s) details</DialogTitle>


            <DialogContent>
    <Typography 
        variant='caption' 
        id='hint' 
        display='flex' 
        alignItems='center' 
        columnGap={0.5}
        marginBottom={2}
    >
        <InfoOutlinedIcon fontSize='inherit' />
        Every Applicant's details will be shared by default if none selected from the list.
    </Typography>

    <TextField 
        id="share-email-id" 
        label="Enter the Email ID to share the Applicants details" 
        variant="outlined" 
        margin='dense' 
        fullWidth
        style={{ marginTop: '14px' }}
        value={shareEmail} 
        onChange={validateEmail} 
    />
</DialogContent>

            <DialogActions>
                <Stack flexDirection='row' justifyContent='space-between' width='100%' padding={2}>
                    <ShButton variant='outlined' size='small' onClick={() => setIsDialogOpen(false)}>Cancel</ShButton>
                    <ShButton variant='contained' size='small' disableElevation
                        disabled={shareApplicantsStatus === 'pending' || shareEmail?.trim().length === 0 || isEmailInValid}
                        onClick={() => shareToEmail(shareEmail?.trim())}>
                        {shareApplicantsStatus === 'failed' ? 'Retry' : 'Done'}</ShButton>
                </Stack>
            </DialogActions>
        </Dialog>
    </>);
};