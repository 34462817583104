import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import EditIcon from '@mui/icons-material/Edit';
import EventIcon from '@mui/icons-material/Event';
import FlagIcon from '@mui/icons-material/Flag';
import InfoIcon from '@mui/icons-material/Info';
import ScheduleIcon from '@mui/icons-material/Schedule';
import VisibilityIcon from '@mui/icons-material/Visibility';
import WorkIcon from '@mui/icons-material/Work';
import {
    CardActions,
    CardContent,
    Chip,
    IconButton,
    Stack,
    TableCell,
    Tooltip,
    Typography,
} from "@mui/material";
import { Box } from '@mui/system';
import { InterviewCardStyled } from "Modules/Core/Interviews/Interviews.styled";
import { IInterviewCard, InterviewStatus } from "Modules/Core/Interviews/InterviewsModel";
import UpdateInterviewCancelStatusDialog from 'Modules/Core/Interviews/UpdateInterviewCancelStatusDialog';
import UpdateInterviewCompleteStatusDialog from 'Modules/Core/Interviews/UpdateInterviewCompleteStatusDialog';
import { useAppDispatch, useAppSelector } from 'helpers/hooks';
import moment from "moment";
import { useEffect, useState } from 'react';
import { ShAvatar } from 'shared/SharedStyles/ShDataDisplay';
import { MUIGrey, ShBorderRadius } from 'shared/SharedStyles/styleConstants';
import { getInitialsFromName, stringToColor } from 'shared/utils';
import { getApplicantDetails, resetGetApplicantDetails } from 'store/slices/employer/applicants/applicant-profile-slice';
import { openInterviewDialog } from 'store/slices/employer/interviews/interviews-shared-slice';
import { getInterviewStatusChip, getInterviewTypeChip } from './InterviewsUtilities';
import ViewInterviewDetails from './ViewInterviewDetails';
import { ShButton, ShGreenBtn } from 'shared/SharedStyles/ShInputs';
import DescriptionIcon from '@mui/icons-material/Description';
import LoginIcon from '@mui/icons-material/Login';
import { useNavigate } from 'react-router-dom';

export const InterviewCard = ({ interview, usedInDashboard }: IInterviewCard) => {

    const dispatch = useAppDispatch();
    const [isInterviewStatusDialogOpen, setIsInterviewStatusDialogOpen] = useState<boolean>(false);
    const [currentUpdateStatus, setCurrentUpdateStatus] = useState<InterviewStatus>();
    const intervieweeInitials = getInitialsFromName(interview.candidate.account.first_name + " " + interview.candidate.account.last_name, true, true);
    const getAvatarBackgroundColor = stringToColor(intervieweeInitials);
    const [isInterviewDetailsVisible, setInterviewDetailsVisible] = useState(false);
    const { getApplicantDetailsStatus, applicantDetails } = useAppSelector((state) => state.employer.applicants.applicantProfile);
    const [isEditMode, setIsEditMode] = useState(false);
    const navigate = useNavigate();

    //reset get applicants since we are depending on its status for opening dialog.
    useEffect(() => {
        dispatch(resetGetApplicantDetails());
    }, [dispatch]);

    const getShAvatar = () => {
        return (
            <ShAvatar minWidth={40} height={40} backgroundColor={getAvatarBackgroundColor} >
                <Typography variant='body1'>
                    {intervieweeInitials}
                </Typography>
            </ShAvatar>
        );
    };

    // convert from 24hr to 12hr
    const convertToFormattedTime = (militaryTime: string): string => {
        if (!militaryTime)
            return '';
        const timeArray = militaryTime?.split(':');
        const hours = parseInt(timeArray[0], 10);
        const minutes = parseInt(timeArray[1], 10);

        // Ensure hours and minutes are within valid ranges
        const formattedHours = hours >= 0 && hours <= 23 ? (hours % 12 || 12) : 12;
        const formattedMinutes = minutes >= 0 && minutes <= 59 ? (minutes < 10 ? `0${minutes}` : `${minutes}`) : '00';

        // Determine AM/PM
        const period = hours >= 12 ? 'PM' : 'AM';

        // Construct the final time string
        const finalTime: string = `${formattedHours}:${formattedMinutes} ${period}`;

        return finalTime;
    }

    const isInterviewDatePassed = () => {
        const today = moment().startOf('day');
        const interviewDate = moment(interview.schedule_date).startOf('day');
        return interviewDate.isBefore(today) && interview.status !== 'Completed' && interview.status !== "Cancelled";
    };

    const _openInterviewDialog = () => {
        dispatch(getApplicantDetails({ applicationId: interview.candidateEmployerJobId, jobId: interview.employerjob_id }));
        setIsEditMode(true);
    };

    useEffect(() => {
        if (getApplicantDetailsStatus === 'success' && isEditMode) {
            dispatch(openInterviewDialog({
                isInterviewDialogOpen: true,
                applicantInfo: applicantDetails,
                candidateName: `${interview?.candidate?.account?.first_name} ${interview?.candidate?.account?.last_name}`,
                interviewId: interview.id,
                jobId: interview.employerjob_id
            }));
            dispatch(resetGetApplicantDetails());
            setIsEditMode(false);
        }
    }, [applicantDetails, dispatch, getApplicantDetailsStatus, interview?.candidate?.account?.first_name,
        interview?.candidate?.account?.last_name, interview.employerjob_id, interview.id, isEditMode]);

    return (<>
        {(usedInDashboard !== undefined) && (
            <>
                <InterviewCardStyled variant={'outlined'} borderRadius={usedInDashboard ? '0' : ShBorderRadius}>
                    <CardContent>
                        <Stack rowGap={2}>
                            <Stack>
                                <Stack color='ThreeDFace' display='flex' justifyContent='start' flexDirection="row">
                                    {getInterviewStatusChip(interview.status)}
                                </Stack>
                            </Stack>
                            <Stack direction='row' columnGap={1} alignItems='center'>
                                {getShAvatar()}
                                <Stack justifyContent='space-between' direction='row'>
                                    <Box>
                                        <Typography variant='body2'>
                                            {interview.candidate.account.first_name} {interview.candidate.account.last_name}
                                        </Typography>
                                        <Typography variant='caption' color={MUIGrey}>
                                            {interview.employerjob.name}
                                        </Typography>
                                    </Box>
                                    {interview.re_scheduled_count > 3 &&
                                        <Tooltip title='Interview rescheduled more than 3 times' placement='bottom'>
                                            <FlagIcon color='warning' />
                                        </Tooltip>}
                                </Stack>

                                <Stack direction='row' justifyContent='space-between' ml='auto'>
                                    {getInterviewTypeChip(interview.type_of_interview)}
                                </Stack>
                            </Stack>
                            {!['Accepted', 'Requested'].includes(interview.status) && <Stack direction='row' justifyContent='space-between'>
                                <Typography variant='subtitle2' color={MUIGrey} display='flex' alignItems='center' columnGap={0.4}>
                                    <EventIcon fontSize='small' />{interview.schedule_date}
                                </Typography>
                                <Typography variant='subtitle2' color={MUIGrey} display='flex' alignItems='center' columnGap={0.4}>
                                    <ScheduleIcon fontSize='small' /> {convertToFormattedTime(interview.from_time)} - {convertToFormattedTime(interview.to_time)}
                                </Typography>
                            </Stack>}
                        </Stack>
                    </CardContent>
                    <CardActions>
                        <Stack direction='row' justifyContent='space-between' alignItems='center' width='100%'>
                            <Stack direction='row' spacing={2} alignItems='center' justifyContent='center' display='flex'>
                                <Typography variant='caption'>{interview.status === 'Scheduled' ? 'Created' : 'Last updated'} on:&nbsp;
                                    {interview.status === 'Scheduled' ? moment(interview.created_at).format("MMM DD, YYYY") :
                                        moment(interview.updated_at).format("MMM DD, YYYY")}
                                </Typography>
                                {isInterviewDatePassed() && (
                                    <Chip
                                        icon={<InfoIcon />}
                                        size='small'
                                        label="Incomplete"
                                        color='warning'
                                        variant='outlined'
                                        onClick={() => { setIsInterviewStatusDialogOpen(true); setCurrentUpdateStatus('Completed') }}
                                        style={{ cursor: 'pointer' }}
                                    />
                                )}
                            </Stack>
                            <Stack direction='row' className='action-items'>
                                {['Completed', 'Requested'].includes(interview.status) &&
                                    <IconButton size='small' color='info'
                                        onClick={() => setInterviewDetailsVisible(true)}>
                                        <Tooltip title="View" placement='bottom'>
                                            <VisibilityIcon fontSize='small' />
                                        </Tooltip>
                                    </IconButton>
                                }
                                {["Accepted"].includes(interview.status) &&
                                    <IconButton size='small' color='info'
                                        onClick={() => _openInterviewDialog()}>
                                        <Tooltip title="Schedule interview" placement='bottom'>
                                            <WorkIcon fontSize='small' />
                                        </Tooltip>
                                    </IconButton>
                                }
                                {["Scheduled", "Requested"].includes(interview.status) &&
                                    <IconButton size='small' color='info'
                                        onClick={() => _openInterviewDialog()}>
                                        <Tooltip title={interview.status === 'Cancelled' ? 'Re-schedule' : 'Edit'} placement='bottom'>
                                            <EditIcon fontSize='small' />
                                        </Tooltip>
                                    </IconButton>
                                }
                                {interview.status === 'Scheduled' &&
                                    <IconButton size='small' color='success'
                                        onClick={() => { setIsInterviewStatusDialogOpen(true); setCurrentUpdateStatus('Completed') }}>
                                        <Tooltip title='Complete' placement='bottom'>
                                            <CheckCircleIcon fontSize='small' />
                                        </Tooltip>
                                    </IconButton>
                                }
                                {['Scheduled', 'Accepted', 'Requested'].includes(interview.status) &&
                                    <IconButton size='small' color='error'
                                        onClick={() => { setIsInterviewStatusDialogOpen(true); setCurrentUpdateStatus('Cancelled') }}>
                                        <Tooltip title='Cancel' placement='bottom'>
                                            <CancelIcon fontSize='small' />
                                        </Tooltip>
                                    </IconButton>
                                }
                            </Stack>
                        </Stack>
                    </CardActions>
                </InterviewCardStyled>
            </>
        )}

        {(usedInDashboard === undefined) && (
            <>
                <TableCell>
                    <Stack direction="row" alignItems="center" spacing={1}>
                        {getShAvatar()}
                        <Typography variant="caption">
                            {interview.candidate.account.first_name} {interview.candidate.account.last_name}
                        </Typography>
                        {interview.re_scheduled_count > 3 &&
                            <Tooltip title='Interview rescheduled more than 3 times' placement='bottom'>
                                <FlagIcon color='warning' />
                            </Tooltip>}
                    </Stack>
                </TableCell>
                <TableCell>
                    <Stack color='ThreeDFace' display='flex' justifyContent='start' flexDirection="row">
                        {getInterviewStatusChip(interview.status)}
                    </Stack>
                </TableCell>
                <TableCell>
                    <Typography variant='caption'>
                        {interview.employerjob.name}
                    </Typography>
                </TableCell>
                <TableCell>
                    <Stack direction='row' justifyContent='space-between' ml='auto'>
                        {getInterviewTypeChip(interview.type_of_interview)}
                    </Stack>
                </TableCell>
                <TableCell>
                <Typography variant='caption' ml="24px">
                    {interview.status === 'Scheduled' 
                        ? moment(interview.created_at).format("MMM DD, YYYY") 
                        : moment(interview.updated_at).format("MMM DD, YYYY")}
                </Typography>

                </TableCell>
                <TableCell>
                    <Stack direction='row' className='action-items'>
                        {['Completed', 'Requested'].includes(interview.status) &&
                            <IconButton size='small' color='info'
                                onClick={() => setInterviewDetailsVisible(true)}>
                                <Tooltip title="View" placement='bottom'>
                                    <VisibilityIcon fontSize='small' />
                                </Tooltip>
                            </IconButton>
                        }
                        {["Accepted"].includes(interview.status) &&
                            <IconButton size='small' color='info'
                                onClick={() => _openInterviewDialog()}>
                                <Tooltip title="Schedule interview" placement='bottom'>
                                    <WorkIcon fontSize='small' />
                                </Tooltip>
                            </IconButton>
                        }
                        {["Scheduled", "Requested"].includes(interview.status) &&
                            <IconButton size='small' color='info'
                                onClick={() => _openInterviewDialog()}>
                                <Tooltip title={interview.status === 'Cancelled' ? 'Re-schedule' : 'Edit'} placement='bottom'>
                                    <EditIcon fontSize='small' />
                                </Tooltip>
                            </IconButton>
                        }
                        {interview.status === 'Scheduled' &&
                            <IconButton size='small' color='success'
                                onClick={() => { setIsInterviewStatusDialogOpen(true); setCurrentUpdateStatus('Completed') }}>
                                <Tooltip title='Complete' placement='bottom'>
                                    <CheckCircleIcon fontSize='small' />
                                </Tooltip>
                            </IconButton>
                        }
                        {['Scheduled', 'Accepted', 'Requested'].includes(interview.status) &&
                            <IconButton size='small' color='error'
                                onClick={() => { setIsInterviewStatusDialogOpen(true); setCurrentUpdateStatus('Cancelled') }}>
                                <Tooltip title='Cancel' placement='bottom'>
                                    <CancelIcon fontSize='small' />
                                </Tooltip>
                            </IconButton>
                        }
                    </Stack>
                </TableCell>

                <TableCell>
                    <ShButton sx={{ padding: '4px 8px', fontSize: '0.75rem' }} startIcon={<LoginIcon fontSize="small" />} size='small' variant='contained' color='primary' disableElevation>See Profile</ShButton>
                </TableCell>
                <TableCell>
                    <ShGreenBtn onClick={() => {navigate(`/employer/job/${interview.employerjob_id}/applicant/${interview.candidateEmployerJobId}/details`) }}
                                sx={{ padding: '4px 8px', fontSize: '0.75rem' }}  
                                startIcon={<DescriptionIcon fontSize="small" />} 
                                size='small' variant='contained' color='success' disableElevation> 
                        View Interview Kits 
                    </ShGreenBtn>
                </TableCell>
            </>
        )}
        {currentUpdateStatus === 'Cancelled' && isInterviewStatusDialogOpen &&
            <UpdateInterviewCancelStatusDialog candidateName={`${interview.candidate.account.first_name} ${interview.candidate.account.last_name}`}
                interviewId={interview.id} status={currentUpdateStatus} isDialogOpen={isInterviewStatusDialogOpen}
                setIsDialogOpen={setIsInterviewStatusDialogOpen} googleCalendarId={interview.google_calendar?.id} />}
        {currentUpdateStatus === 'Completed' && isInterviewStatusDialogOpen && (
            <UpdateInterviewCompleteStatusDialog
                candidateName={`${interview.candidate.account.first_name} ${interview.candidate.account.last_name}`}
                interviewId={interview.id}
                status={currentUpdateStatus}
                isDialogOpen={isInterviewStatusDialogOpen}
                setIsDialogOpen={setIsInterviewStatusDialogOpen} googleCalendarId={interview.google_calendar?.id}
            />
        )}
        {isInterviewDetailsVisible && (
            <ViewInterviewDetails
                applicantName={`${interview?.candidate?.account?.first_name} ${interview?.candidate?.account?.last_name}`}
                interviewId={interview.id}
                isDialogOpen={isInterviewDetailsVisible}
                setIsDialogOpen={() => setInterviewDetailsVisible(false)}
                employerJobName={interview.employerjob.name}
            />
        )}
    </>);
};