import { Box, Fade, Stack, Typography } from "@mui/material";
import { CandidateSummaryProps } from "../ApplicantCandidateAiSummaryModels";
import { ShChip } from "shared/SharedStyles/ShDataDisplay";
import { ShPaper } from "shared/SharedStyles/ShSurfaces";
import { ShGreen } from "shared/SharedStyles/styleConstants";

export const EducationSection: React.FC<CandidateSummaryProps> = ({ candidate }) => {

    if (!candidate?.education?.length) return null;

    return (
        <Fade in={true} timeout={800}>
            <Stack spacing={2}>
                {candidate.education.length > 0 && (
                    <Box mt={2}>
                        <Typography fontSize={18}>EDUCATION</Typography>
                        {candidate.education.map((edu, index) => (
                            <ShPaper key={index} sx={{ padding: 2, my: 1 }} variant="outlined" leftBorderWidth="4px" leftBorderColor="#FF5733">
                            <Typography variant="subtitle1">{edu.degree}</Typography>
                            <Stack direction='row' spacing={2} alignItems='center'>
                                <Typography variant="body2" color="text.secondary">
                                    🎓 {edu.university} ({edu.duration})
                                </Typography>
                                <ShChip size='small' customSize="small" bgColor={ShGreen} textColor='white' label={edu.hasGraduated ? 'GRADUATED' : 'Currently Attending'}/>
                            </Stack>
                            </ShPaper>
                        ))}
                    </Box>
                )}
            </Stack>
        </Fade>
    );
}
