import DownloadIcon from '@mui/icons-material/Download';
import { Box, CircularProgress, LinearProgress, Stack, Tab } from '@mui/material';
import { useAppDispatch, useAppSelector } from "helpers/hooks";
import { useNotification } from "Modules/Core/Notification";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import { ShButton, ShGreenBtn } from "shared/SharedStyles/ShInputs";
import { ShTabs } from "shared/SharedStyles/ShNavigation";
import { downloadApplicantHiringGuide, downloadCandidateProfile, resetDownloadApplicantHiringGuide, resetDownloadApplicantProfile } from "store/slices/employer/applicants/applicant-actions-slice";
import { IApplicant } from "../ApplicantsModel";
import { ApplicantAdditionalQuestions } from './ApplicantAdditionalQuestions';
import { ApplicantComments } from "./ApplicantComments";
import { ApplicantEEO } from './ApplicantEEO';
import { ApplicantProfile } from "./ApplicantProfile";
import { ApplicantDetailsSection, hashToSectionMap } from "./ApplicantProfileModel";
import { ApplicantResume } from "./ApplicantResume";
import { ApplicantAiSummary } from '../ApplicantPipeline/ApplicantAiSummary';
import { ActivityLogTable } from '../ActivityLogTable';
import { fetchActivityLogs } from "store/slices/employer/applicants/applicant-profile-slice";
import { ApplicantInterviewKitTabs } from './ApplicantInterviewKitTabs';

export const ApplicantProfileWrapper = () => {

    const notification = useNotification();
    const dispatch = useAppDispatch();
    const { applicantDetails, getApplicantDetailsStatus } = useAppSelector((state) => state.employer.applicants.applicantProfile);
    const { downloadingGuideStatus, downloadingGuideResponse, downloadCandidateProfileResponse, downloadCandidateProfileStatus } = useAppSelector(state => state.employer.applicants.applicantActions);
    const navigate = useNavigate();
    const location = useLocation();
    const [currentSection, setCurrentSection] = useState<ApplicantDetailsSection>(hashToSectionMap[location.hash] || 'profile');
    const employer_id = useAppSelector((state) => state.employer.employerDetails?.employerDetails?.id);
    const [activityLogData, setActivityLogData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (location.hash) {
            setCurrentSection(hashToSectionMap[location.hash] || 'profile');
        }
    }, [location.hash]);

    const handleTabChange = (event: React.ChangeEvent<{}>, newValue: ApplicantDetailsSection) => {
        setCurrentSection(newValue);
        navigate(`#${newValue}`);
    };


    const downloadHiringGuide = (applicant: IApplicant) => {
        dispatch(downloadApplicantHiringGuide({ applicantId: applicant.candidateEmployerJobId, applicantName: applicant.fullName }));
    }

    const dispatchDownloadCandidateProfile = (applicant: IApplicant) => {
        dispatch(downloadCandidateProfile({ applicantId: applicant.candidateEmployerJobId, applicantName: applicant.fullName }));
    }

    // Show snackbar notifications on download list as csv.
    useEffect(() => {
        if (downloadingGuideStatus === 'success' || downloadingGuideStatus === 'failed') {
            notification.displayNotification({
                open: true,
                type: downloadingGuideStatus === 'success' ? 'success' : 'error',
                message: downloadingGuideResponse ?? 'Download successful'
            });
            dispatch(resetDownloadApplicantHiringGuide());
        }
    }, [dispatch, downloadingGuideResponse, downloadingGuideStatus, notification]);

    useEffect(() => {
        if (downloadCandidateProfileStatus === 'success' || downloadCandidateProfileStatus === 'failed') {
            notification.displayNotification({
                open: true,
                type: downloadCandidateProfileStatus === 'success' ? 'success' : 'error',
                message: downloadCandidateProfileResponse ?? 'Download successful'
            });
            dispatch(resetDownloadApplicantProfile());
        }
    }, [dispatch, downloadCandidateProfileResponse, downloadCandidateProfileStatus, notification]);

    useEffect(() => {
        const fetchActivityLogData = async () => {
            if (applicantDetails) {
              const params = {
                candidate_id: applicantDetails.candidateId,
                activity_feed_id: null, 
                employerjob_id: applicantDetails.employerJobId,
                employer_id: employer_id,
                stage: 'new',
                sortBy: null,
                sortOrder: 'desc',
                page: null,
                limit: 20,
              };
    
              try {
                setIsLoading(true);
                const response = await dispatch(fetchActivityLogs(params));
                const fetchedData = response.payload?.data || [];
                setActivityLogData(fetchedData);
              } catch (error) {
                console.error('Error fetching activity logs:', error);
              } finally {
                setIsLoading(false); 
              }
            }
        };
    
        if (currentSection === 'activitylog') {
          fetchActivityLogData();
        }
    }, [applicantDetails, employer_id, currentSection, dispatch]);

    return (
        <>
            {getApplicantDetailsStatus === 'pending' && <LinearProgress/>}
            <Stack direction='row' alignItems='center' justifyContent='space-between' rowGap={1} flexWrap='wrap' paddingX={{ xs: 2, sm: 3, md: 4, lg: 4 }} marginBottom={1} marginTop={1}>
                <ShTabs highlightVariant='underline' aria-label="Applicant Tabs" variant="scrollable" scrollButtons='auto'
                    onChange={handleTabChange}
                    allowScrollButtonsMobile value={currentSection}>
                    <Tab label="Smart View" value="smart-view" />
                    <Tab label="Summary" value="profile" />
                    <Tab label="Resume" value="resume" />
                    <Tab label="Comments" value="comments" />
                    <Tab label="Additional Questions" value="additionalquestions" />
                    <Tab label="EEO" value="eeo" />
                    <Tab label="Interview Kits" value="questions" />
                </ShTabs>
                <Stack direction="row" spacing={1}>
                    {!applicantDetails?.atsPurchased &&
                        <ShButton disableElevation
                            onClick={(e) => {
                                e.stopPropagation();
                                applicantDetails && dispatchDownloadCandidateProfile(applicantDetails);
                            }}
                            variant="outlined"
                            startIcon={
                                downloadCandidateProfileStatus === 'pending' ? (
                                    <CircularProgress size={20} color="inherit" />
                                ) : (
                                    <DownloadIcon fontSize="medium" />
                                )
                            }
                            disabled={downloadCandidateProfileStatus === 'pending'}
                        >
                            {downloadCandidateProfileStatus === 'pending' ? 'Downloading...' : 'Profile'}
                        </ShButton>
                    }
                    {!applicantDetails?.atsPurchased &&
                        <ShGreenBtn
                            startIcon={
                                downloadingGuideStatus === 'pending' ? (
                                    <CircularProgress size={20} color="inherit" />
                                ) : (
                                    <DownloadIcon fontSize="medium" />
                                )
                            }
                            onClick={(e) => {
                                e.stopPropagation();
                                applicantDetails && downloadHiringGuide(applicantDetails);
                            }}
                            variant="outlined"
                            disabled={downloadingGuideStatus === 'pending'}
                            disableElevation
                            color="primary"
                            size="small"
                        >
                            {downloadingGuideStatus === 'pending' ? 'Downloading...' : 'Hiring Guide'}
                        </ShGreenBtn>}
                </Stack>
            </Stack>

            <Box padding={{ xs: 2, sm: 3, md: 4, lg: 4 }}>
                {currentSection === 'smart-view' && <ApplicantAiSummary />}
                {currentSection === 'profile' && <ApplicantProfile />}
                {currentSection === 'resume' && <ApplicantResume />}
                {currentSection === 'comments' && <ApplicantComments />}
                {currentSection === 'additionalquestions' && <ApplicantAdditionalQuestions />}
                {currentSection === 'eeo' && <ApplicantEEO />}
                {currentSection === 'questions' && <ApplicantInterviewKitTabs />}
                {currentSection === 'activitylog' && isLoading ? (
                    <div>Loading Activity Log...</div>
                ) : (
                    currentSection === 'activitylog' && <ActivityLogTable activityLogs={activityLogData} />
                )}
            </Box>
        </>

    );
}