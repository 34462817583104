import { Box, Stack, Typography } from "@mui/material";
import { IsMdScreen, IsXsScreen } from "helpers/hooks";
import { ResourceTypography } from "Modules/Marketing/Resources/Resources.styled";
import { Link as RouterLink } from "react-router-dom";
import { ShButton } from "shared/SharedStyles/ShInputs";
import { ShContainer } from "shared/SharedStyles/ShLayouts";
import CommonQuestionSidebar from "../CommonQuestionSidebar";

export const Careers = () => {
  const isMdScreen = IsMdScreen();
  const paddingValue = IsXsScreen() ? 1 : 3;

  return (
    <>
      <Stack padding={paddingValue} direction={"row"}>
        <ShContainer>
          <ResourceTypography variant="h5" fontWeight={"bold"} gutterBottom>
            Customize Careers Page
          </ResourceTypography>
          <ResourceTypography variant="body1" paragraph>
            Personalize your careers page to match your brand identity and give potential candidates a smooth, professional experience.
          </ResourceTypography>

          <ResourceTypography
            paddingTop={2}
            variant="h6"
            fontWeight={"bold"}
            gutterBottom
          >
            Upload Company Logo
          </ResourceTypography>
          <Box paddingTop={1} paddingBottom={3}>
            <Stack spacing={1}>
              <Typography variant="body1" paragraph>
                Upload your company logo to be displayed on your careers page. This helps candidates easily recognize your brand.
              </Typography>
              <Typography variant="body1" paragraph>
                Recommended size: 200 x 200 px (PNG or JPEG format). You can update or change the logo anytime to match your latest branding.
              </Typography>
            </Stack>
          </Box>

          <ResourceTypography
            paddingTop={1}
            variant="h6"
            fontWeight={"bold"}
            gutterBottom
          >
            Upload Banner Image
          </ResourceTypography>
          <Box paddingTop={1} paddingBottom={3}>
            <Stack spacing={1}>
              <Typography variant="body1" paragraph>
                Upload a banner image to make your careers page more visually appealing and organized. This image will appear at the top of the page for better presentation.
              </Typography>
              <Typography variant="body1" paragraph>
                Recommended size: 1200 x 400 px (PNG or JPEG format). You can update or change the banner anytime based on your preference.
              </Typography>
            </Stack>
          </Box>

          <ResourceTypography variant="h6" fontWeight={"bold"} gutterBottom>
            Manage Careers Page
          </ResourceTypography>
          <Box paddingTop={1} paddingBottom={2}>
            <Stack spacing={2}>
              <Typography variant="body1" paragraph>
                Easily manage and personalize your careers page to highlight job openings and reflect your company's branding.
              </Typography>
              <ShButton
                component={RouterLink}
                to="/employer/settings/account/customize-careers-page#career-page-logos"
                color="primary"
                variant="contained"
              >
                Customize Careers Page
              </ShButton>
            </Stack>
          </Box>
        </ShContainer>
        {!isMdScreen && <CommonQuestionSidebar />}
      </Stack>
    </>
  );
};

export default Careers;
