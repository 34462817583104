import {
  ICreateZoomMeetPayload,
  TInterviewDialogUsedIn,
} from "Modules/Core/Interviews/InterviewsModel";
import { IJob, MatchType } from "Modules/Core/JobsTs/JobsModel";
import { Dispatch, SetStateAction } from "react";
import {
  ApiState,
  ICareerSeekerNavLinks,
  ILabelValueBase,
  IPaginationParams,
  ISortBase,
  ISortParamsBase,
  ISummaryComponentBase,
  LayoutTypes,
  SortOrder,
} from "shared/SharedModels";
import { ICandidateAiSummary } from "./ApplicantPipeline/ApplicantCandidateAiSummaryModels";
import { IHtmlInterviewKit, IResumeFocusedAIQuestions } from "./ApplicantProfile/ApplicantProfileModel";

export type ApplicantsSortCols = "first_name" | "created_at" | "rating";
export type SkillVal = "THREE" | "TWO" | "ONE";
export type FilterType = "stages" | "fit" | "keywords" | "rating";
export type TInterviewStage =
  | "new"
  | "second_interview"
  | "hired"
  | "auto_rejected"
  | "offer"
  | "phone_screening"
  | "rejected"
  | "interview"
  | "not_qualified"
  | "reviewed";

export enum JobStages {
  Interview = "INTERVIEW",
  Hired = "HIRED",
  Rejected = "REJECTED",
  Phone_Screening = "PHONE_SCREENING",
  Second_Interview = "SECOND_INTERVIEW",
  Offer = "OFFER",
}

/* Component Interfaces */
export interface IFilterComponent {
  applyFilters: (filters: IApplicantsFilter) => void;
  selectedFilters: IApplicantsFilter;
}

export interface IApplicantsComponentBase extends IChangeStage {
  applicantsList: IApplicant[];
  applicantsSummary: IApplicantsSummary;
  aiCandidatesSummary?: ICandidatesAiSummary;
  selectedApplicants: number[];
  selectApplicant: (applicantId: number) => void;
  selectAll: () => void,
  isAllApplicantsSelected: () => boolean,
  getApplicantSummary: (candidateEmployerJobId: number) => void;
  getAiCandidateSummary: (applicant: IApplicant) => void;
  goToApplicantProfile: (applicant: IApplicant) => void;
  downloadHiringGuide: (applicant: IApplicant) => void;
  isAtsPurchased?: boolean;
  isIncompleteApplicantView?: boolean,
}

export interface IApplicantsToolBar extends IFilterComponent, ISortBase<ApplicantsSortCols> {
  applicantList: IApplicant[];
  layoutType: LayoutTypes,
  setLayoutType: Dispatch<SetStateAction<LayoutTypes>>,
  removeFilter: (fltrType: FilterType, val: string) => void,
  isSmScreen: boolean,
  searchKey: string,
  searchInApplicants: (searchKey: string) => void
};

export interface IApplicantsPipelineToolBar extends IFilterComponent, ISortBase<ApplicantsSortCols> {
  applicantList: IApplicant[];
  removeFilter: (fltrType: FilterType, val: string) => void,
  searchKey: string,
  searchInApplicants: (searchKey: string) => void
};



export interface IApplicantsFooter {
  paginationParams: IPaginationParams;
  getApplicants: (
    pageNo?: number,
    pageSize?: number,
    filters?: IApplicantsFilter,
    sortParams?: ISortParamsBase<ApplicantsSortCols>
  ) => void;
  isSmScreen: boolean;
  selectedApplicants: number[];
  jobId: number;
  layoutType: LayoutTypes,
  countInThisPage: number
}

export interface IApplicantsTable extends IApplicantsComponentBase, IChangeStage, ISortBase<ApplicantsSortCols> {
}

export interface IChangeStage {
  changeStage: (
    stage: Lowercase<TInterviewStage>,
    candidateId: number,
    sendEmail: boolean
  ) => void;
}

export interface IApplicantRowModel extends IChangeStage {
  applicant: IApplicant;
  selectedApplicants: number[];
  selectApplicant: (applicantId: number) => void;
  isAtsPurchased?: boolean;
  isIncompleteApplicantView?: boolean;
  indx: number;
  applicantsSummary: IApplicantsSummary;
  aiCandidatesSummary?: ICandidatesAiSummary;
  getApplicantSummary: (candidateEmployerJobId: number) => void;
  goToApplicantProfile: (applicant: IApplicant) => void;
  downloadHiringGuide: (applicant: IApplicant) => void;
  getAiCandidateSummary: (applicant: IApplicant) => void;
}

/* Data models */
export interface IApplicant {
  applicationCompletedAt: string;
  atsPurchased: boolean;
  candidateEmployerJobId: number;
  candidateId: number;
  id?: number;
  resumeId?:number,
  commentTotal: number;
  distortionCount: number;
  email: string;
  employerJobId: number;
  firstName: string;
  jobStage: TInterviewStage;
  stageModifiedAt: Date;
  lastName: string;
  matchType: string;
  resumeScore: number;
  rating: number;
  isExpanded?: boolean;
  changeStateStatus?: ApiState;
  changeStateResponse?: string;
  updateRatingStatus?: ApiState;
  updateRatingResponse?: string;
  fullName?: string;
  hasReceivedInterviewEmail?: boolean;
  hasReceivedOfferLetterEmail?: boolean;
  hasReceivedRejectionEmail?: boolean;
  addedRejectionReasons?: boolean;
  isBookmarked?: boolean;
  education?: {
    degree: string;
    duration?: number;
    university: string;
    hasGraduated: boolean;
  }[];
  currentJobTitle?: string;
  currentEmployer?: string;
  totalYearsExperience?: number;
  tags?: string[];
}

export interface IApplicantsFilter {
  stages: ILabelValueBase<string, TInterviewStage>[];
  fit: (ILabelValueBase & { matchTypeKey?: MatchType })[];
  keywords: ILabelValueBase[];
  rating: ILabelValueBase[];
}
export interface IApplicantSummaryParams {
  jobId: number;
  candidateEmployerJobId: number;
  isExpandPanel: boolean;
}

interface IEduQualifications {
  hasRequirement: boolean;
  name: string;
}

interface ISkillReq {
  name: string;
  skillRating: SkillVal;
}

export interface IComment {
  comment: string;
  commentDate: string;
  commentId: number;
  commentOwner: string;
}

export interface IApplicantSummary {
  baseballCardUrl?: string;
  commentSummaries?: IComment[];
  educationQualificationRequirements?: IEduQualifications[];
  hasAppliedBeforeRequirementsChange?: boolean;
  skillRequirements?: ISkillReq[];
  getSummaryStatus?: ApiState;
  getSummaryResponse?: string;
  getFingerprintImageStatus?: ApiState;
  getFingerprintImageResponse?: string;
}

export interface IApplicantsSummary {
  [candidateEmployerJobId: number]: IApplicantSummary;
}

export interface ICandidatesAiSummary {
  [candidate_employerjobs_id: number]: ICandidateAiSummary | undefined;
}


/* API interfaces */
export interface IApplicantsApiParams {
  jobId: number;
  pgNo: number;
  pgSize: number;
  sortCol: ApplicantsSortCols;
  sortDir: SortOrder;
  search?: string;
  finalMatches?: string;
  stages?: string;
  keywords?: string;
  ratings?: string;
}

export interface IApplicantSummaryResponse {
  data: IApplicantSummary;
  message: string;
  status: {
    message: string;
    statusCode: number;
    timeStamp: string;
    version: string;
  };
}

export interface IApplicantsListResponse {
  data: IApplicant[];
  stats: IPaginationParams & { atsPurchased: boolean };
  message: string;
  status: {
    message: string;
    statusCode: number;
    timeStamp: string;
    version: string;
  };
}

export interface IApplicantsListState {
  getApplicantsListStatus: ApiState;
  getApplicantsListResponse: string | undefined;
  getBatchAutoRejectStatus?: ApiState
  getBatchAutoRejectResponse?: string | undefined;
  applicantsList: IApplicant[];
  aiSuggestedApplicants?: IApplicant[],
  paginationParams: IPaginationParams;
  isAtsPurchased?: boolean;
  applicantsSummary: IApplicantsSummary;
  applicantSummary?: IApplicantSummary;
  aiCandidatesSummary?: ICandidatesAiSummary
  aiCandidateSummary?:ICandidateAiSummary | undefined,
  getApplicantSummaryStatus?: ApiState;
  getApplicantSummaryResponse?: string;
  applicantFingerPrintImageUrl?: string;
  getApplicantFingerPrintImageStatus?: ApiState;
  getApplicantFingerPrintImageResponse?: string;
  stageChangeStatus?: ApiState;
  generateCandidateAiSummaryStatus?: ApiState,
  generateCandidateAiSummaryResponse?:string,
}

interface IEmployerJobData {
  name: string;
  city: string;
  company_alias: string;
  is_complete: boolean;
  completed_at: string;
}

export interface IJobApplyHistoryData {
  id: number | null;
  candidate_id: number;
  employer_id: number;
  employerjob_id: number;
  final_match: string;
  fingerprint_key: string;
  fingerprint_match: string | null;
  fingerprint_score: string | null;
  submitted_at: string;
  is_archived: boolean;
  is_background_checks: boolean;
  organizational_profile_match: null | string;
  postingsource_id: number;
  processed_fingerprint: boolean;
  rating: null | string;
  referrer: null | string;
  requirement_score: string | null;
  resume_id: number;
  stage: string;
  asked_to_complete_assessment_at: string | null;
  asked_to_complete_assessment_by_id: null | string;
  asked_to_retake_assessment_at: null | string;
  application_completed_at: null | string;
  retook_assessment_at: null | number;
  is_resume_processing: boolean;
  complete_link: null | string;
  retake_link: null | string;
  resume_link: null | string;
  view_resume_link: string;
  sends_alerts: boolean;
  employerjob: IEmployerJobData;
  createdAt: string;
}

export interface IApplicantActionsState {
  zoomMeetCreationStatus: ApiState;
  zoomMeetCreationResponse: string;
  zoomMeetResponse?: IZoomMeetResponse;
  zoomMeetUpdateStatus: ApiState;
  zoomMeetUpdateResponse: string;
  zoomMeetDeleteStatus: ApiState;
  zoomMeetDeleteResponse: string;
  rejectionEmailStatus?: ApiState;
  rejectionEmailResponse?: string;
  downloadingGuideStatus?: ApiState;
  downloadingGuideResponse?: string;
  downloadCandidateProfileStatus?: ApiState;
  downloadCandidateProfileResponse?: string;
  getRejectReasonStatus?: ApiState;
  getRejectReasonResponse?:  IRejectionNoteResponse | null; 
  bookmarks: { [applicantId: number]: boolean };
}

export interface IZoomMeetAPIPayload {
  payload?: ICreateZoomMeetPayload;
  zoomId?: number;
}

export interface IApplicantsListActionsState {
  shareApplicantsStatus: ApiState;
  shareApplicantsResponse?: string;
  downloadAsCSVStatus: ApiState;
  downloadAsCSVResponse?: string;
  downloadAsExcelStatus: ApiState;
  downloadAsExcelResponse?: string;
  inviteApplicantsStatus?: ApiState;
  inviteApplicantsResponse?: string;
  getJobFilterKeywordsStatus?: ApiState;
  getJobFilterKeywordsResponse?: string;
  jobFilterKeywords?: string[];
}

export interface IApplicantCommentsState {
  getCommentsStatus: ApiState;
  getCommentsResponse: string;
  comments: IApplicantComment[];
  postCommentsStatus: ApiState;
  postCommentsResponse: string;
  updateCommentsStatus: ApiState;
  updateCommentsResponse: string;
}

export interface IApplicantStageParams {
  applicantId: number;
  payload: { stage: Lowercase<TInterviewStage>; sendEmail: boolean };
}

export interface IActivityLogParams {
  candidate_id: number;
  activity_feed_id?: number | null; // Allow null
  employerjob_id: number;
  employer_id?: number | null;
  stage?: string | null;
  sortBy?: string | null;
  sortOrder: string;
  page?: number | null;
  limit: number;
}

export interface IActivityLog {
  id: number;
  candidate_id: number;
  activity_feed_id: number;
  employerjob_id: number;
  employer_id: number;
  stage: string;
  message: string;
  created_at: string;
  updated_at: string;
}

export interface IActivityLogTable {
  activityLogs: IActivityLog[];
}

export interface IShareToEmailParams {
  toEmail: string;
  employerJobId: number;
  applicantIds: string;
}

export interface ICareerSeekerResources {
  resourceItems: ICareerSeekerNavLinks[];
}

export interface IApplicantHistoryItem {
  title: string;
  company: string;
  location: string;
  applied?: string;
  complete: boolean;
}

export interface IApplicantHistoryComponent {
  ApplicationHistoryList: IJobApplyHistoryData[];
}
export interface IApplicantCommentComponent {
  applicantId: number;
  commentId?: number;
  label?: string;
  isAddition?: boolean;
}

export interface IApplicantComment {
  id?: number;
  body?: string;
  subject?: string;
  user_id: number;
  user_name?: string;
  commentable_type?: string;
  commentable_id: number;
  created_at?: string;
  updated_at?: string;
}

export interface IApplicantCommentPost {
  applicantId: number;
  payload: { body: string };
}

export interface IApplicantCommentUpdate extends IApplicantCommentPost {
  commentId: number;
}

export interface IApplicantRatingComponent {
  applicant: IApplicant;
  className?: string;
  usedIn: "list" | "profile";
}

export interface IApplicantRatingUpdate {
  applicantId: number;
  payload: { rating: number };
}

export interface IIncompleteApplicant {
  id: number;
  is_archived: boolean;
  employer_id: number;
  candidate_id: number;
  employerjob_id: number;
  asked_to_retake_assessment_at: string;
  asked_to_complete_assessment_at: string;
  asked_to_complete_assessment_by_id: string;
  retook_assessment_at: string;
  fingerprint_score: string;
  requirement_score: string;
  fingerprint_match: string;
  final_match: string;
  type: string;
  is_bookmarked: boolean,
  callback_when_complete: string;
  callback_when_incomplete: string;
  api_key: string;
  organizational_profile_match: string;
  stage: string;
  fingerprint_key: string;
  processed_fingerprint: true;
  resume_keyword_matches: string;
  application_completed_at: string;
  postingsource_id: number;
  resume_match_score: number;
  user_agent: string;
  referrer: string;
  ip: string;
  rating: string;
  resume_id: number;
  submitted_at: string;
  is_background_checks: boolean;
  createdAt: string;
  updatedAt: string;
  candidate: {
    first_name: string;
    last_name: string;
    middle_name: string;
    account: {
      email: string,
    }
  };
  employerjob: { id: number };
  resume: { id: number; text: string };
}

export interface IIncompleteApplicantsState {
  getIncompleteApplicantsListStatus: ApiState;
  getIncompleteApplicantsListResponse?: string;
  incompleteApplicantsList: IIncompleteApplicant[];
  downloadAsCSVStatus?: ApiState;
  paginationParams: IPaginationParams;
}

export interface IAllApplicantData {
  id: number;
  is_archived: boolean;
  employer_id: number;
  candidate_id: number;
  employerjob_id: number;
  stage: string;
  final_match: string;
  stage_modified_at: string;
  resume_match_score: number;
  rating: number;
  resume_id: number;
  thank_you_email_sent: boolean;
  has_received_rejection_email: boolean;
  has_received_offer_letter_email: boolean;
  has_received_interview_email: boolean;
  createdAt: string;
  updatedAt: string;
  candidate: {
    id: number;
    first_name: string;
    middle_name?: string | null;
    last_name: string;
    account_id: number;
    account: {
      id: number;
      first_name: string;
      middle_name?: string | null;
      last_name: string;
      email: string;
    };
  };
  employerjob: {
    id: number;
    name: string;
    created_at: string;
  };
  resume?: {
    id: number;
    text?: string | null;
  };
}

export interface IApplicantsState {
  getApplicantsStatus: ApiState;
  getApplicantsResponse?: string;
  applicantsList: IAllApplicantData[];
  paginationParams: IPaginationParams;
}


export interface ISuggestedQuestions {
  questions_to_ask?: string;
  suggested_responses?: string;
  strong_responses?: string;
  weak_responses?: string;
  questions?: {
    title?: string;
    blurb?: string;
    questions?: {
      [key: string]: {
        head?: string;
        follows?: string[];
      };
    };
    strong_responses?: string[];
    weak_responses?: string[];
  }[];
}

export interface IGetApplicantDetailsParams {
  applicationId: number;
  jobId: number;
}

export interface IGetApplicantDetailsParams {
  applicationId: number;
  jobId: number;
}

export interface IGetapplicantRejectionNotesParams {
  applicationId: number;
}

export interface IApplicantProfileState {
  getApplicantDetailsStatus?: ApiState;
  getApplicantDetailsResponse?: string;
  applicantDetails?: IApplicant;
  getFullFingerPrintImageStatus?: ApiState;
  getFullFingerPrintImageResponse?: string;
  fullFingerPrintImage?: string;
  getSuggestedQuestionsStatus?: ApiState;
  getSuggestedQuestionsResponse?: string;
  suggestedQuestions?: ISuggestedQuestions;
  getApplicantResumeStatus?: ApiState;
  getApplicantResumeResponse?: string;
  resumeS3Url?: string;
  updateApplicantProfileApiStatus?: ApiState;
  updateApplicantProfileApiResponse?: string;
  updatePasswordApiStatus?: ApiState;
  updatePasswordApiResponse?: string;
  fetchEEOQuestionsStatus?: ApiState,
  eeoQuestionAndAnswers?: { [idx: number]: string }; // This will map idx (question index) to the candidate's answer
  eeoQuestionAndAnswersStatus?: ApiState;

  additionalQuestions?: {
    [questionId: number]: {
      answer: string;        // Candidate's answer to the question
      autoReject: boolean;   // Whether auto-reject is enabled for the question
    };
  };
  fetchAdditionalQuestionsStatus?: ApiState;
  fetchAdditionalQuestionsResponse?: string;

  candidateDocumentURL?: string;
  fetchCandidateDocumentStatus?: ApiState;
  fetchCandidateDocumentResponse?: string;

  aiInterviewQuestions?: IResumeFocusedAIQuestions;
  fetchAndGenerateAiResumeFocusedQuestionsStatus?: ApiState;
  fetchAndGenerateAiResumeFocusedQuestionsResponse?: string;

  fetchAndGenerateAiInterivewKitStatus?: ApiState;
  fetchAndGenerateAiInterivewKitResponse?: string;
  aiInterviewHTMLKit?: IHtmlInterviewKit;

  saveInterviewAnswersStatus?: ApiState;
  saveInterviewAnswersResponse?: string;
}

export interface IUpdateApplicantProfile {
  first_name: string;
  last_name: string;
  middle_name?: string;
  applicantId?: number;
}

export interface IUpdateApplicantPassword {
  oldPassword: string;
  newPassword: string;
  confirmPassword: string;
}

export interface IZoomMeetResponseData {
  agenda: string;
  created_at: string;
  duration: number;
  encrypted_password: string;
  h323_password: string;
  host_email: string;
  host_id: string;
  id: number;
  join_url: string;
  password: string;
  pre_schedule: boolean;
  pstn_password: string;
  settings: any;
  start_time: string;
  start_url: string;
  status: string;
  timezone: string;
  topic: string;
  type: number;
  uuid: string;
}

export interface IZoomMeetResponse {
  id: number;
  response: IZoomMeetResponseData;
}


export interface IRejectionNoteResponse {
  statusCode:number,
  message: string,
  data ?:{
    rejectReason: string;
    rejectionNotes: string
  }
}

export interface IInviteApplicants {
  isInviteApplicantsOpen: boolean;
  setIsInviteApplicantsOpen: Dispatch<SetStateAction<boolean>>;
  job: IJob;
  initialEmails?: string;
}

export interface IInviteApplicantsApiParams {
  jobId: number;
  body: IInviteApplicantsPayload;
}

export interface IInviteApplicantsPayload {
  to: string;
  subject: string;
  body: string;
}

export interface IRejectionEmailDialog {
  isRejectionEmailDialogOpen: boolean;
  setIsRejectionEmailDialogOpen: Dispatch<
    SetStateAction<{ [key: number]: boolean } | undefined>
  >;
  changeStage: (
    stage: Lowercase<TInterviewStage>,
    candidateId: number,
    sendEmail: boolean
  ) => void;
  applicant: IApplicant;
  jobId: number;
}

export interface IRejectionEmailPayload {
  subject: string;
  to: string;
  cc: string;
  bcc: string;
  data: string;
}

export interface IRejectionReasonPayload {
  rejectReasons: string;
  rejectionNotes: string;
}

export interface IApplicantSummaryProps
  extends ISummaryComponentBase<IApplicantSummary> {
  changeStage: (
    stage: Lowercase<TInterviewStage>,
    candidateId: number,
    sendEmail: boolean
  ) => void;
  applicantId: number;
  applicant: IApplicant;
  aiCandidateSummary?: ICandidateAiSummary,
  goToApplicantProfile: (applicant: IApplicant) => void;
  downloadHiringGuide: (applicant: IApplicant) => void;
  isAtsPurchased?: boolean;
}

export interface IStagesTogglerProps
  extends IChangeStage,
  Partial<HTMLElement> {
  applicant: IApplicant;
  includeOnlyInterviews?: boolean;
  usedIn: TInterviewDialogUsedIn;
}

export type TInterviewsStage = TInterviewStage | "all" | "interviews";

export interface IStageFolder {
  label: string;
  value: TInterviewsStage;
}

export type TApplicantsMatchTypeMapForFilter = {
  [key in MatchType]: MatchType | MatchType[];
};
