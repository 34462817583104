import { useAppDispatch, useAppSelector } from 'helpers/hooks';
import { Stack, Typography, LinearProgress, Box } from '@mui/material';
import { ShPaper } from 'shared/SharedStyles/ShSurfaces';
import { fetchAndGenerateAiInterivewKit, resetAiCandidateInterviewKit } from 'store/slices/employer/applicants/applicant-profile-slice';
import { useCallback, useEffect } from 'react';
import { shBlue } from 'shared/SharedStyles/styleConstants';

export const ApplicantInterviewKit = () => {
  const dispatch = useAppDispatch();
  const {
    applicantDetails,
    aiInterviewHTMLKit,
    fetchAndGenerateAiInterivewKitStatus
  } = useAppSelector((state) => state.employer.applicants.applicantProfile);

  const handleGenerateInterviewKit = useCallback(() => {
    if (!applicantDetails?.resumeId || !applicantDetails?.candidateEmployerJobId) return;

    dispatch(
      fetchAndGenerateAiInterivewKit({
        resume_id: applicantDetails.resumeId,
        candidate_employerjobs_id: applicantDetails.candidateEmployerJobId
      })
    );
  }, [applicantDetails, dispatch]);

  useEffect(() => {
    if (applicantDetails?.resumeId && applicantDetails?.candidateEmployerJobId) {
      handleGenerateInterviewKit();
    }

    return () => {
      dispatch(resetAiCandidateInterviewKit());
    };
  }, [applicantDetails, dispatch, handleGenerateInterviewKit]);

  const isLoading = fetchAndGenerateAiInterivewKitStatus === 'pending';

  // Function to clean and process the HTML
  const cleanInterviewKitHTML = (html: string) => {
    if (!html) return "";

    // Remove any Markdown-style code block indicators (e.g., ```html or ```).
    let cleanedHtml = html.replace(/```html|```/g, "").trim();

    // Replace "+" with a ✅ checkmark
    cleanedHtml = cleanedHtml.replace(/\+\s/g, "✅ ");

    // Replace "-" with a ❌ red X
    cleanedHtml = cleanedHtml.replace(/-\s/g, "❌ ");

    return cleanedHtml;
  };

  return (
    <Stack p={3}>
      <ShPaper variant="outlined" headerSection borderRadius={0}>
        <Typography variant="body1" gutterBottom>
          Technical Interview Kit
        </Typography>
        <Typography variant="body2" gutterBottom color="gray">
          These questions are generated based on the candidate's resume.
          You can create your own custom interview questions, take detailed notes, rate their responses,
          and note anything interesting that stands out during the interview.
        </Typography>
      </ShPaper>

      {isLoading ? (
        <>
          <Typography variant="body2" gutterBottom color="gray">
            Fetching Kit (This might take a few minutes)...
          </Typography>
          <LinearProgress />
        </>
      ) : (
        <ShPaper variant="outlined" leftBorderColor={shBlue} leftBorderWidth="4px">
          {aiInterviewHTMLKit && (
            <Box
              mt={2}
              sx={{ "& ol": { pl: 3 }, "& li": { mb: 1.5 } }}
              dangerouslySetInnerHTML={{ __html: cleanInterviewKitHTML(aiInterviewHTMLKit.interview_kit) }}
            />
          )}
        </ShPaper>
      )}
    </Stack>
  );
};

export default ApplicantInterviewKit;
