import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { IApplyJobQuestionnaireState, IJobApplicationRequirement } from "Modules/Core/ApplyJob/ApplyJobModel";
import { AxiosResponse } from "axios";
import httpAdapterInstance from "configs/HttpAdapterConfig";
import { PURGE } from "redux-persist";
import { EmployerApiEndpoints } from "shared/ApiEndpoints";
import { IBaseResponse } from "shared/SharedModels";
import { DefaultAPIErrorMsg } from "shared/constants";

const initialApplyJobQuestionnaireState: IApplyJobQuestionnaireState = {};

export const getJobRequirements = createAsyncThunk<IBaseResponse<IJobApplicationRequirement[]>,
    { jobId: number }, { rejectValue: IBaseResponse }>(
        "getJobRequirements",
        async ({ jobId }, { rejectWithValue }) => {
            return await httpAdapterInstance
                .get(`${EmployerApiEndpoints.JOB_REQUIREMENTS}/${jobId}/requirements`)
                .then((response: AxiosResponse<IBaseResponse<IJobApplicationRequirement[]>>) => response?.data)
                .catch((error) => {
                    throw rejectWithValue(error.response.data);
                });
        }
    );

export const updateJobRequirementAnswer = createAsyncThunk<IBaseResponse<IJobApplicationRequirement>, {
    jobId: number, payload: { requirementId: number, requirementLevelId: number }
}, { rejectValue: IBaseResponse }>(
    "updateJobRequirementAnswer",
    async ({ jobId, payload }, { rejectWithValue }) => {
        return await httpAdapterInstance
            .put(`${EmployerApiEndpoints.JOB_REQUIREMENTS}/${jobId}/requirements`, payload)
            .then((response: AxiosResponse<IBaseResponse<IJobApplicationRequirement>>) => response?.data)
            .catch((error) => {
                throw rejectWithValue(error.response.data);
            });
    }
);

export const completeAtsJobApplication = createAsyncThunk<{ message: string }, number, { rejectValue: IBaseResponse }>(
    "completeAtsJobApplication",
    async (jobApplicationId: number, { rejectWithValue }) => {
        return httpAdapterInstance
            .put(`${EmployerApiEndpoints.JOB_APPLY_COMPLETE}/${jobApplicationId}/ats_complete`)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                throw rejectWithValue(error.response.data)
            })
    }
);

export const fetchEEOQuestionsJobApply = createAsyncThunk<IBaseResponse<[]>, { jobId: string }, { rejectValue: IBaseResponse }>(
    "fetchEEOQuestionsJobApply",
    async ({ jobId }, { rejectWithValue }) => {
        return await httpAdapterInstance
            .get(`${EmployerApiEndpoints.JOBS}/${jobId}/eeo_questionnaire`) 
            .then((response: AxiosResponse<IBaseResponse<[]>>) => response?.data)
            .catch((error) => {
                return rejectWithValue(error.response.data);
            });
    }
);

export const updateCandidateEEOQuestionsJobApply = createAsyncThunk<
  IBaseResponse,
  { jobApplicationId: number, eeoAnswers: { [idx: number]: string } },
  { rejectValue: IBaseResponse }>(
  "updateCandidateEEOQuestionsJobApply",
  async ({ jobApplicationId, eeoAnswers }, { rejectWithValue }) => {
    return httpAdapterInstance
      .put(`${EmployerApiEndpoints.JOB_REQUIREMENTS}/${jobApplicationId}/eeo_application_answers`, {
        eeoAnswers, 
      })
      .then((response: AxiosResponse<IBaseResponse>) => {
        return response.data; 
      })
      .catch((error) => {
        return rejectWithValue(error.response?.data || error.message);
      });
  }
);

export const fetchCandidateEEOQuestionsAnswers = createAsyncThunk<
  IBaseResponse<{ [idx: number]: string }>, 
  { jobApplicationId: number },
  { rejectValue: IBaseResponse }
>(
  "fetchCandidateEEOQuestionsJobApply",
  async ({ jobApplicationId }, { rejectWithValue }) => {
    return httpAdapterInstance
      .get(`${EmployerApiEndpoints.JOB_REQUIREMENTS}/${jobApplicationId}/eeoApplicationQuestionAnswers`)
      .then((response: AxiosResponse<IBaseResponse<{ [idx: number]: string }>>) => {
        return response.data;
      })
      .catch((error) => {
        return rejectWithValue(error.response?.data || error.message);
      });
  }
);

export const updateCandidateAdditionalQuestionsJobApply = createAsyncThunk<
  IBaseResponse,
  { jobApplicationId: number, additionalQuestions: { questionId: string, answer: string, autoReject: boolean }[] },
  { rejectValue: IBaseResponse }
>(
  "updateCandidateAdditionalQuestionsJobApply",
  ({ jobApplicationId, additionalQuestions }, { rejectWithValue }) => {
    return httpAdapterInstance
      .put(
        `${EmployerApiEndpoints.JOB_REQUIREMENTS}/${jobApplicationId}/additional_question_answers`,
        { additionalQuestions }
      )
      .then((response: AxiosResponse<IBaseResponse>) => {
        return response.data; 
      })
      .catch((error) => {
        return rejectWithValue(error.response?.data || error.message); 
      });
  }
);

export const fetchCandidateAdditionalQuestionsAnswers = createAsyncThunk<
  IBaseResponse<{ [questionId: string]: { answer: string, autoReject: boolean } }>, 
  { jobApplicationId: number },
  { rejectValue: IBaseResponse }
>(
  "fetchCandidateAdditionalQuestionsJobApply",
  ({ jobApplicationId }, { rejectWithValue }) => {
    return httpAdapterInstance
      .get(
        `${EmployerApiEndpoints.JOB_REQUIREMENTS}/${jobApplicationId}/candidate_additional_question_answers`
      )
      .then((response: AxiosResponse<IBaseResponse<{ [questionId: string]: { answer: string, autoReject: boolean } }>>) => {
        return response.data; 
      })
      .catch((error) => {
        return rejectWithValue(error.response?.data || error.message);  
      });
  }
);

export const uploadAdditionalCandidateDocuments = createAsyncThunk<IBaseResponse, { payload: FormData, questionId: string, jobApplicationId: string }, { rejectValue: IBaseResponse }>(
  "uploadAdditionalCandidateDocuments",
  async ({ payload, questionId, jobApplicationId }, { rejectWithValue }) => {
    const file = payload.get("additional_document");
    const body = new FormData();
    if(file){
      body.append("additional_document", file);  
      body.append("questionId", questionId); 
      body.append("jobApplicationId", jobApplicationId);
    }
    return httpAdapterInstance
      .post(`${EmployerApiEndpoints.CANDIDATES}/additional-documents`, body)
      .then((response: AxiosResponse<IBaseResponse>) => response?.data)
      .catch((error) => {return rejectWithValue(error.response?.data || error.message)});
  }
);


const applyJobQuestionnaireSlice = createSlice({
    name: "applyJobQuestionnaire",
    initialState: initialApplyJobQuestionnaireState,
    reducers: {
        setIsAdditionalQuestionRequiredAnswersComplete: (state, action) => {
            state.isAdditionalQuestionRequiredAnswersComplete = action.payload;
        },
        resetGetJobRequirements: (state) => {
            state.getJobApplicationRequirementsStatus = 'idle';
            state.getJobApplicationRequirementsResponse = ''
        },
        resetCompleteAtsJobApplication: (state) => {
            state.completeAtsJobApplicationStatus = 'idle';
            state.completeAtsJobApplicationResponse = ''
        },
    },
    extraReducers: (builder) => {
        // On Store PURGE reset the state
        builder.addCase(PURGE, () => {
            return initialApplyJobQuestionnaireState;
        });
        // get job requirements
        builder.addCase(getJobRequirements.pending, (state) => {
            state.getJobApplicationRequirementsStatus = 'pending';
        });
        builder.addCase(getJobRequirements.fulfilled, (state, action) => {
            state.getJobApplicationRequirementsStatus = 'success';
            state.jobApplicationRequirements = action.payload?.data;
            // If no "applicant_requirement_level_id" is null then Questionnaire is complete.
            state.isQuestionnaireComplete = action.payload?.data
                ?.filter(req => req.applicant_requirement_level_id === null)?.length === 0;
        });
        builder.addCase(getJobRequirements.rejected, (state, action) => {
            state.getJobApplicationRequirementsStatus = 'failed';
            state.getJobApplicationRequirementsResponse = action?.payload?.message ?? DefaultAPIErrorMsg;
        });
        // update job requirements
        builder.addCase(updateJobRequirementAnswer.pending, (state, action) => {
            // Update api status on requirement level
            const updateIndex = state.jobApplicationRequirements
                ?.findIndex(jobReq => jobReq.id === action.meta.arg.payload.requirementId);
            if (updateIndex !== undefined && updateIndex >= 0 && state.jobApplicationRequirements) {
                state.jobApplicationRequirements[updateIndex].updateJobRequirementAnswerStatus = 'pending';
                state.jobApplicationRequirements[updateIndex].updateJobRequirementAnswerId = action.meta.arg.payload.requirementLevelId;
            }
        });
        builder.addCase(updateJobRequirementAnswer.fulfilled, (state, action) => {
            // Update api status on requirement level
            const updateIndex = state.jobApplicationRequirements
                ?.findIndex(jobReq => jobReq.id === action.meta.arg.payload.requirementId);
            if (updateIndex !== undefined && updateIndex >= 0 && state.jobApplicationRequirements) {
                state.jobApplicationRequirements[updateIndex].updateJobRequirementAnswerStatus = 'success';
            }
            // Update candidate answer in "jobApplicationRequirements"
            state.jobApplicationRequirements?.forEach(jobReq => {
                if (jobReq.id === action.payload.data.requirement_id) {
                    jobReq.applicant_requirement_level_id = action.payload.data.applicant_requirement_level_id;
                }
            });
            // Update 'isQuestionnaireComplete' as candidate answers.
            // If no "applicant_requirement_level_id" is null then Questionnaire is complete.
            state.isQuestionnaireComplete = state.jobApplicationRequirements
                ?.filter(req => req.applicant_requirement_level_id === null)?.length === 0;
        });
        builder.addCase(updateJobRequirementAnswer.rejected, (state, action) => {
            // Update api status on requirement level
            const updateIndex = state.jobApplicationRequirements
                ?.findIndex(jobReq => jobReq.id === action.meta.arg.payload.requirementId);
            if (updateIndex !== undefined && updateIndex >= 0 && state.jobApplicationRequirements) {
                state.jobApplicationRequirements[updateIndex].updateJobRequirementAnswerStatus = 'failed';
                state.jobApplicationRequirements[updateIndex].updateJobRequirementAnswerResponse =
                    action?.payload?.message ?? DefaultAPIErrorMsg;
            }
        });
        // complete ats job application
        builder.addCase(completeAtsJobApplication.pending, (state) => {
            state.completeAtsJobApplicationStatus = 'pending';
        });
        builder.addCase(completeAtsJobApplication.fulfilled, (state, action) => {
            state.completeAtsJobApplicationStatus = 'success';
            state.completeAtsJobApplicationResponse = action?.payload?.message;
        });
        builder.addCase(completeAtsJobApplication.rejected, (state, action) => {
            state.completeAtsJobApplicationStatus = 'failed';
            state.completeAtsJobApplicationResponse = action?.payload?.message ?? DefaultAPIErrorMsg;
        });

        // Handle fetching EEO questions
        builder.addCase(fetchEEOQuestionsJobApply.pending, (state) => {
            state.fetchEEOQuestionsStatus = 'pending';
        });
        builder.addCase(fetchEEOQuestionsJobApply.fulfilled, (state, action) => {
            state.fetchEEOQuestionsStatus = 'success';
            state.eeoQuestions = action.payload.data; 
        });
        builder.addCase(fetchEEOQuestionsJobApply.rejected, (state, action) => {
            state.fetchEEOQuestionsStatus = 'failed';
        });

        // Handle updating eeo answers
        builder.addCase(updateCandidateEEOQuestionsJobApply.pending, (state) => {
            state.eeoQuestionAndAnswersStatus = "pending";  
          });
          builder.addCase(updateCandidateEEOQuestionsJobApply.fulfilled, (state, action) => {
            state.eeoQuestionAndAnswersStatus = "success";  
          });
          builder.addCase(updateCandidateEEOQuestionsJobApply.rejected, (state, action) => {
            state.eeoQuestionAndAnswersStatus = "failed"; 
          });

          //Handle fetching eeo answers
          builder.addCase(fetchCandidateEEOQuestionsAnswers.pending, (state) => {
            state.fetchEEOQuestionsStatus = 'pending'; 
          });
          builder.addCase(fetchCandidateEEOQuestionsAnswers.fulfilled, (state, action) => {
            state.fetchEEOQuestionsStatus = 'success'; 
            state.eeoQuestionAndAnswers = action.payload.data; 
          });
          builder.addCase(fetchCandidateEEOQuestionsAnswers.rejected, (state, action) => {
            state.fetchEEOQuestionsStatus = 'failed'; 
          });

        // Fetching Candidate's Additional Questions Answers
        builder.addCase(fetchCandidateAdditionalQuestionsAnswers.pending, (state) => {
            state.fetchAdditionalQuestionsStatus = 'pending'; 
        });
        builder.addCase(fetchCandidateAdditionalQuestionsAnswers.fulfilled, (state, action) => {
            state.fetchAdditionalQuestionsStatus = 'success'; 
            state.additionalQuestions = action.payload.data; // Storing the candidate's answers and autoReject
        });
        builder.addCase(fetchCandidateAdditionalQuestionsAnswers.rejected, (state, action) => {
            state.fetchAdditionalQuestionsStatus = 'failed'; 
        });
    
        // Updating Candidate's Additional Questions Answers
        builder.addCase(updateCandidateAdditionalQuestionsJobApply.pending, (state) => {
            state.additionalQuestionsStatus = "pending";  
        });
        builder.addCase(updateCandidateAdditionalQuestionsJobApply.fulfilled, (state, action) => {
            state.additionalQuestionsStatus = "success";  
        });
        builder.addCase(updateCandidateAdditionalQuestionsJobApply.rejected, (state, action) => {
            state.additionalQuestionsStatus = "failed"; 
        });

         // Updating Candidate's Additional Questions Answers
          builder.addCase(uploadAdditionalCandidateDocuments.pending, (state) => {
            state.updateCandidateDocumentsStatus = "pending";  
          });
          builder.addCase(uploadAdditionalCandidateDocuments.fulfilled, (state, action) => {
              state.updateCandidateDocumentsStatus = "success";  
              state.updateCandidateDocumentsResponse = action?.payload?.message ?? DefaultAPIErrorMsg;
          });
          builder.addCase(uploadAdditionalCandidateDocuments.rejected, (state, action) => {
              state.updateCandidateDocumentsStatus = "failed"; 
              state.updateCandidateDocumentsResponse = action?.payload?.message ?? DefaultAPIErrorMsg;
          });

    }
});

export const { resetGetJobRequirements, resetCompleteAtsJobApplication , setIsAdditionalQuestionRequiredAnswersComplete} = applyJobQuestionnaireSlice.actions;

export default applyJobQuestionnaireSlice;
