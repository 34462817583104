import { useCallback, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'helpers/hooks';
import { Stack, TextField, Typography, LinearProgress, CircularProgress, Box, IconButton } from '@mui/material';
import { AddCircleOutline, Delete } from '@mui/icons-material';
import {
    fetchAndGenerateAiResumeFocusedQuestions,
    resetAiCandidateInterviewQuestions,
    saveInterviewQuestionResponses
} from 'store/slices/employer/applicants/applicant-profile-slice';
import { ShPaper } from 'shared/SharedStyles/ShSurfaces';
import { ShButton, ShGreenBtn } from 'shared/SharedStyles/ShInputs';
import { ShGreen } from 'shared/SharedStyles/styleConstants';
import { ConfirmationDialog } from 'shared/SharedComponents/ConfirmationDialog/ConfirmationDialog';
import { Save } from '@mui/icons-material';

const CHARACTER_LIMIT = 300;

export const ApplicantAIInterviewQuestions = () => {
  const dispatch = useAppDispatch();
  const { applicantDetails, aiInterviewQuestions, fetchAndGenerateAiResumeFocusedQuestionsStatus } = useAppSelector(
    (state) => state.employer.applicants.applicantProfile
  );

  const [questions, setQuestions] = useState<string[]>([]);
  const [answers, setAnswers] = useState<Record<number, string>>({});
  const [savingIndex, setSavingIndex] = useState<number | null>(null);
  const [isSavingAll, setIsSavingAll] = useState(false);
  const [deleteIndex, setDeleteIndex] = useState<number | null>(null); // Track index for deletion
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false); // Track dialog visibility

  // Fetch AI interview questions
  const handleGenerateAiQuestions = useCallback(() => {
    if (!applicantDetails?.resumeId || !applicantDetails?.candidateEmployerJobId) return;

    dispatch(
      fetchAndGenerateAiResumeFocusedQuestions({
        resume_id: applicantDetails.resumeId,
        candidate_employerjobs_id: applicantDetails.candidateEmployerJobId
      })
    );
  }, [applicantDetails, dispatch]);

  // Load questions and existing answers
  useEffect(() => {
    if (applicantDetails?.resumeId && applicantDetails?.candidateEmployerJobId) {
      handleGenerateAiQuestions();
    }

    return () => {
      dispatch(resetAiCandidateInterviewQuestions());
    };
  }, [applicantDetails, dispatch, handleGenerateAiQuestions]);

  // Populate existing questions and answers when fetched
  useEffect(() => {
    if (aiInterviewQuestions) {
      setQuestions(aiInterviewQuestions.questions || []);
  
      // Ensure answers match the length of questions and handle undefined answers
      const updatedAnswers = aiInterviewQuestions.questions.reduce((acc, _, index) => {
        acc[index] = aiInterviewQuestions.answers?.[index] || ""; // Default to empty string if no answer exists
        return acc;
      }, {} as Record<number, string>);
  
      setAnswers(updatedAnswers);
    }
  }, [aiInterviewQuestions]);
  

  const handleAnswerChange = (index: number, value: string) => {
    if (value.length <= CHARACTER_LIMIT) {
      setAnswers((prev) => ({ ...prev, [index]: value }));
    }
  };

  const handleSaveResponse = async (index: number) => {
    if (!applicantDetails?.candidateEmployerJobId) return;

    setSavingIndex(index);

    await dispatch(
      saveInterviewQuestionResponses({
        candidate_employerjobs_id: applicantDetails.candidateEmployerJobId,
        questions, // Pass all questions
        responses: { [index]: answers[index] } // Pass the answer for the specific question
      })
    );

    setSavingIndex(null);
  };

  const handleSubmitAllResponses = async () => {
    if (!applicantDetails?.candidateEmployerJobId) return;

    setIsSavingAll(true);

    await dispatch(
      saveInterviewQuestionResponses({
        candidate_employerjobs_id: applicantDetails.candidateEmployerJobId,
        questions,
        responses: answers
      })
    );

    setIsSavingAll(false);
  };

  // Add a new question
  const handleAddQuestion = () => {
    setQuestions((prev) => [...prev, ""]);
    setAnswers((prev) => ({ ...prev, [questions.length]: "" })); // Add empty answer for the new question
  };

  const handleQuestionChange = (index: number, value: string) => {
    setQuestions((prev) => prev.map((q, i) => (i === index ? value : q))); // Update only the modified question
  };

  // Open delete confirmation dialog
  const openDeleteDialog = (index: number) => {
    setDeleteIndex(index);
    setIsDeleteDialogOpen(true);
  };

  // Confirm deletion
  const handleConfirmDelete = async () => {
    if (deleteIndex === null || !applicantDetails?.candidateEmployerJobId) return;
  
    // Remove the question at the deleteIndex
    const updatedQuestions = questions.filter((_, i) => i !== deleteIndex);
    
    // Remove the answer corresponding to the deleted question
    const updatedAnswers = { ...answers };
    delete updatedAnswers[deleteIndex];
  
    // Reindex the remaining answers to avoid index shifting issues
    const reindexedAnswers: Record<number, string> = {};
    Object.keys(updatedAnswers).forEach((key) => {
      const newIndex = parseInt(key, 10);
      if (newIndex > deleteIndex) {
        // Shift the answer index
        reindexedAnswers[newIndex - 1] = updatedAnswers[newIndex];
      } else {
        reindexedAnswers[newIndex] = updatedAnswers[newIndex];
      }
    });
  
    await dispatch(
      saveInterviewQuestionResponses({
        candidate_employerjobs_id: applicantDetails.candidateEmployerJobId,
        questions: updatedQuestions,
        responses: reindexedAnswers
      })
    );
  
    setQuestions(updatedQuestions);
    setAnswers(reindexedAnswers);
    setIsDeleteDialogOpen(false);
    setDeleteIndex(null);
  };
  
  return (
    <Stack p={3}>
      <ShPaper variant='outlined' headerSection borderRadius={0}>
        <Typography variant="body1" gutterBottom>
          Resume Focused Interview Questions
        </Typography>
        <Typography variant="body2" gutterBottom color='gray'>
          These questions are generated based on the candidate's resume. 
          You can create your own custom interview questions, take detailed notes, rate their responses, and note anything interesting that stands out during the interview.
        </Typography>
      </ShPaper>


      <ShPaper variant='outlined' borderRadius={0}>
        {fetchAndGenerateAiResumeFocusedQuestionsStatus === "pending" ? (
          <>Loading...<LinearProgress /></>
        ) : questions.length > 0 ? (
          questions.map((question, index) => (
            <Stack key={index} spacing={1} pb={1}>
              <ShPaper variant='outlined' leftBorderWidth="4px" leftBorderColor={ShGreen}>
                <Stack direction='row' justifyContent='space-between' pb={1} alignItems='center'>
                  <TextField
                    label={`Question ${index + 1}`}
                    variant="outlined"
                    size='small'
                    fullWidth
                    value={question}
                    onChange={(e) => handleQuestionChange(index, e.target.value)}
                  />
                  <IconButton color="warning" onClick={() => openDeleteDialog(index)}>
                    <Delete />
                  </IconButton>
                </Stack>
                <TextField
                  label={`Answer ${index + 1}`}
                  variant="outlined"
                  multiline
                  rows={3}
                  value={answers[index] || ""}
                  onChange={(e) => handleAnswerChange(index, e.target.value)}
                  fullWidth
                  helperText={`${answers[index]?.length || 0}/${CHARACTER_LIMIT} characters`}
                />
                <Box justifySelf='flex-end'>
                  <ShButton variant="contained" color="primary" size='small' disableElevation
                    onClick={() => handleSaveResponse(index)}
                    disabled={savingIndex === index} >
                    {savingIndex === index ? <>Saving...<CircularProgress size={20} /></> : "Save Response"}
                  </ShButton>
                </Box>
              </ShPaper>
            </Stack>
          ))
        ) : (
          <Typography>No interview questions available.</Typography>
        )}
        <Box pt={2}>
          <ShButton size='small' variant="contained" onClick={handleAddQuestion} startIcon={<AddCircleOutline />}>
            Add Question
          </ShButton>
        </Box>
      </ShPaper>



      <Box pt={2}>
      <ShGreenBtn startIcon={<Save />} variant="contained" color="primary" disableElevation onClick={handleSubmitAllResponses} disabled={isSavingAll}>
          {isSavingAll ? (
            <>Saving...<CircularProgress size={20} /></>
          ) : (
            <>
              Save All Responses
            </>
          )}
        </ShGreenBtn>
      </Box>

      {/* Confirmation Dialog for Deleting a Question */}
      <ConfirmationDialog
        isDialogOpen={isDeleteDialogOpen}
        title="Confirm Deletion"
        contentText="Are you sure you want to delete this question and response?"
        cancelButtonLabel="Cancel"
        confirmButtonLabel="Delete"
        onCancel={() => setIsDeleteDialogOpen(false)}
        onConfirm={handleConfirmDelete}
      />
    </Stack>
  );
};

export default ApplicantAIInterviewQuestions;
