import { Fade, Stack, Typography } from "@mui/material";
import { CandidateSummaryProps } from "../ApplicantCandidateAiSummaryModels";
import { ShPaper } from "shared/SharedStyles/ShSurfaces";

export const ProjectsSection: React.FC<CandidateSummaryProps> = ({ candidate }) => {

    if (!candidate?.projects?.length) return null;

    return (
    <Fade in={true} timeout={800}>
        <Stack spacing={2}>
            {/* Projects Section */}
            {candidate.projects.length > 0 && (
            <Stack spacing={1}>
                <Typography fontSize={18}>PROJECTS</Typography>
                {candidate.projects.map((project, index) => (
                    <ShPaper key={index} variant="outlined" leftBorderWidth="4px" leftBorderColor="#FF5733">
                        <Typography variant="subtitle1">{project.name}</Typography>
                        <Typography variant="body2">{project.description}</Typography>
                    </ShPaper>
                ))}
            </Stack>
            )}

        </Stack>
    </Fade>
    );
}
