import LogoutIcon from '@mui/icons-material/Logout';
import { IconButton, Skeleton, Stack, Toolbar, Tooltip, Typography } from "@mui/material";
import { useAppDispatch, useAppSelector } from 'helpers/hooks';
import { useEffect } from 'react';
import { ShLogo } from 'shared/SharedComponents/ShLogo';
import ThemeToggler from 'shared/SharedComponents/ThemeToggler';
import { ShAlert } from 'shared/SharedStyles/ShFeedback';
import { ShAppBar } from 'shared/SharedStyles/ShSurfaces';
import { ShGreen } from 'shared/SharedStyles/styleConstants';
import { logoutAccount } from 'store/slices/auth-v2/auth-v2-slice';
import { resetApplyForJob } from 'store/slices/candidate/apply-job/apply-job-home-slice';
import { resetGetJobRequirements } from 'store/slices/candidate/apply-job/apply-job-questionnaire.slice';
import { resetUploadApplicantResume } from 'store/slices/candidate/apply-job/resume-slice';
import { resetGetCandidateNotificationSettings, resetUpdateCandidateNotificationSettings } from 'store/slices/candidate/candidate-notifications-slice';
import { resetGetCandidateApplicationHistory, resetGetCandidateDetails, resetSignUpCandidate } from 'store/slices/candidate/candidate-profile-slice';
import { resetSurvey } from 'store/slices/candidate/survey/survey-slice';
import { getCareerPageDetails, getCompanyLogo } from 'store/slices/employer/employer-sub-domain/employer-sub-domain-slice';

export const CandidateTopNav = () => {

    const dispatch = useAppDispatch();
    const { isAccountLoggedIn } = useAppSelector((state) => state.auth.auth);
    const {companyLogo, getCompanyLogoApiStatus, getCompanyLogoApiResponse} = useAppSelector(state => state.employer.employerSubDomain);
    const { jobDetailsByCode } = useAppSelector(state => state.employer.employerJobs.jobInfo);
    const { careerPage} = useAppSelector(state => state.employer.employerSubDomain);
    useEffect(() => {
        if (!companyLogo && jobDetailsByCode?.employer_id ) {
          dispatch(getCompanyLogo({ empId: jobDetailsByCode?.employer_id  }));
        }
      }, [dispatch, jobDetailsByCode?.employer_id , companyLogo]);

      useEffect(() => {
        if (jobDetailsByCode?.employer_id && !careerPage) {
            dispatch(getCareerPageDetails({ employerId: jobDetailsByCode?.employer_id }))
           
        }
    }, [dispatch, jobDetailsByCode?.employer_id, careerPage]);

    const logoutCandidate = () => {
        dispatch(logoutAccount({ logoutType: 'user' }));
        dispatch(resetSurvey());
        dispatch(resetApplyForJob());
        dispatch(resetGetCandidateDetails());
        dispatch(resetGetJobRequirements());
        dispatch(resetSignUpCandidate());
        dispatch(resetGetCandidateApplicationHistory());
        dispatch(resetGetCandidateNotificationSettings());
        dispatch(resetUpdateCandidateNotificationSettings());
        dispatch(resetSurvey());
        dispatch(resetUploadApplicantResume());
    }

    return (
        <ShAppBar position="sticky" sx={{borderBottom: `4px solid ${careerPage?.primary_color || ShGreen}` }}>
            <Toolbar>
                <Stack width='100%' direction='row' justifyContent='space-between' alignItems='center'>
                    <Stack direction='row' spacing={1} alignItems={'center'}>
                        {companyLogo === undefined ? (
                            <>
                                <ShLogo link="/candidate" />
                            </>
                        ) : getCompanyLogoApiStatus === "pending" ? (
                            <Skeleton width="25%" height="100%" animation="wave" />
                        ) : getCompanyLogoApiStatus === "success" && companyLogo ? (
                            <a href="/candidate">
                                <img src={companyLogo} alt="Company Logo" style={{ width: "100%", height: "auto", maxHeight: "75px" }}
                                />
                            </a>
                        ) : (
                            <>
                                {getCompanyLogoApiStatus === "failed" && (
                                    <ShAlert severity="error">{getCompanyLogoApiResponse}</ShAlert>
                                )}
                                <ShLogo link="/candidate" />
                            </>
                        )}

                            {jobDetailsByCode?.company_alias && 
                                <Typography variant="h6" color='gray'> Careers at {jobDetailsByCode?.company_alias}</Typography>
                            }
                        </Stack>
                    <Stack direction='row' alignItems='center'>
                        {/* <DesktopOnlyStack direction='row' columnGap={2}> 
                            <ContactInfo showDivider dividerOrientation='vertical' /> 
                        </DesktopOnlyStack> */}
                        <ThemeToggler />
                        {isAccountLoggedIn && <Tooltip title="Log out">
                            <IconButton size="large" onClick={logoutCandidate}>
                                <LogoutIcon color='warning' />
                            </IconButton>
                        </Tooltip>}
                    </Stack>
                </Stack>
            </Toolbar>
        </ShAppBar>
    );
};