
export type ApplicantDetailsSection = 'profile' | 'resume' | 'comments' | 'questions' | 'stage' | 'eeo' | 'additionalquestions' | 'smart-view' | 'activitylog';

export enum ApplicantPathEnum {
    Profile = 'profile',
    Resume = 'resume',
    Comments = 'comments',
    Questions = 'questions',
    Stage = 'stage',
    EEO = 'eeo',
    AdditionalQuestions = 'additionalquestions',
    SmartOverview = 'smart-view',
    ActivityLog = 'activitylog'
};

export  const hashToSectionMap: { [key: string]: ApplicantDetailsSection } = {
    '#profile': 'profile',
    '#resume': 'resume',
    '#comments': 'comments',
    '#additionalquestions': 'additionalquestions',
    '#eeo': 'eeo',
    '#questions': 'questions',
    '#smart-view': 'smart-view',
    '#ActivityLog': 'activitylog',
};


export interface IResumeFocusedAIQuestions {
    id: number;
    candidate_employerjobs_id: number;
    questions: string[]; // Array of 10 AI-generated questions
    answers?: string[]; // Array of 10 answers (nullable if not answered yet)
    createdAt: string;
}

export interface IHtmlInterviewKit {
    id: number;
    candidate_employerjobs_id: number;
    interview_kit: string; // The HTML Content for the AI Interview Kit, this has questions & expected answers based on their resume
    created_at: string;
    updated_at: string;
  }