import { Stack, Typography, Divider, Fade } from "@mui/material";
import { ShChip } from "shared/SharedStyles/ShDataDisplay";
import { ShPaper } from "shared/SharedStyles/ShSurfaces";
import { ShGreen, shBlue } from "shared/SharedStyles/styleConstants";
import { CandidateSummaryProps } from "../ApplicantCandidateAiSummaryModels";

const formatExperienceDuration = (yearsDecimal: number) => {
    const totalMonths = Math.round(yearsDecimal * 12); // Convert years to months and round
    const years = Math.floor(totalMonths / 12);
    const remainingMonths = totalMonths % 12;

    return years > 0
        ? `${years} year${years !== 1 ? "s" : ""}${remainingMonths ? ` ${remainingMonths} month${remainingMonths !== 1 ? "s" : ""}` : ""}`
        : `${remainingMonths} month${remainingMonths !== 1 ? "s" : ""}`;
};

export const ExperienceSection: React.FC<CandidateSummaryProps> = ({ candidate }) => {

    if (!candidate?.previous_experience?.length) return null;

    return (
    <Fade in={true} timeout={800}>
        <Stack spacing={2} overflow='auto' maxHeight={600}>
            <Typography fontSize={18}>EXPERIENCE</Typography>
            {candidate.previous_experience.map((exp, index) => (
                <ShPaper key={index} sx={{ padding: 2, my: 1 }} variant="outlined" leftBorderWidth="4px" leftBorderColor={ShGreen}>
                    <Stack direction='row' spacing={1} justifyContent='space-between' pb={1}>
                        <Typography variant="subtitle1" gutterBottom>
                            {exp.job_title} @ {exp.employer}
                        </Typography>
                        <ShChip label={
                            <Typography variant="caption">
                                {exp?.duration} 
                            </Typography>
                        }
                        bgColor={shBlue}
                        textColor="white"
                        customSize="xs"/>
                    </Stack>
                    <Divider/>
                    <Typography variant="body2" color="text.secondary" gutterBottom pt={1}>
                        {formatExperienceDuration(Number(exp.years))}
                    </Typography>
                    <Typography variant="body2">{exp.description}</Typography>
                </ShPaper>
            ))}
        </Stack>
    </Fade>
    );
}

export default ExperienceSection;
