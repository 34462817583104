const helpPageCards = [
    {
        title: "Getting Started",
        desc: "Learn how to create a job posting and manage candidates efficiently.",
        image: 'card1',
        path: "/employer/help/getting-started",
    },
    {
        title: "Managing Candidates",
        desc: "Explore tools and tips for effectively managing candidates throughout the hiring process.",
        image: 'card2',
        path: "/employer/help/managing-candidates",
    },
    {
        title: "Optimize job posting for Job Fit & Resume Score",
        desc: "Enhance job fit and improve resume scores by targeting key skills and weighted keywords.",
        image: 'card8',
        path: "/employer/help/optimize-job-posting",
    },
    {
        title: "Account Customization",
        desc: "Discover how to optimize your account settings with custom permissions.",
        image: 'card3',
        path: "/employer/help/account-customization",
    },
    {
        title: "Account Security",
        desc: "Learn best practices for securing your account and protecting sensitive data.",
        image: 'card4',
        path: "/employer/help/account-security",
    },
    {
        title: "Billing and Payments",
        desc: "Understand how billing works and manage payments for your account.",
        image: 'card5',
        path: "/employer/help/billing-payments",
    },
    {
        title: "Contact Support",
        desc: "Need assistance? Contact our support team for personalized help.",
        image: 'card9',
        path: "/employer/help/contact-support",
    },
    {
        title: "How to Set Permissions",
        desc: "Guide to configuring user permissions and access levels within your organization.",
        image: 'card3',
        path: "/employer/help/set-permissions",
    },
        {
        title: "How to Post a Job",
        desc: "Learn how to create and publish job postings to attract candidates.",
        image: 'card4',
        path: "/employer/help/post-a-job",
    },
    {
        title: "How to Manage Interviews",
        desc: "Guide to scheduling and managing interviews.",
        image: 'card8',
        path: "/employer/help/manage-interviews",
    },
    {
        title: "How to Create a Careers Page",
        desc:  "Learn how to set up and personalize your Careers page.",
        image: 'card11',
        path: "/employer/help/careers",
    },
    {
        title: "How to Create and Manage Tasks",
        desc: "Guide to Creating and Assigning Tasks for Personal and Team Use.",
        image: 'card12',
        path: "/employer/help/tasks",
    },
   ];
  
  export default helpPageCards;
  