import { Box, Typography, Divider } from "@mui/material";
import {
  AdminJobFeaturesCellOrder,
  AdminJobFeaturesTableHeaders,
  AdminJobReviewCellOrder,
  AdminJobReviewTableHeaders,
  AdminJobBundlesTableHeaders,
  AdminJobBundlesCellOrder,
} from "Modules/Core/Admin/AdminDashboard/adminDashboardConstants";
import { useAppDispatch, useAppSelector } from "helpers/hooks";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ShAdminTable from "shared/SharedComponents/ShAdminTable/ShAdminTable";
import { renderFingerprintImage } from "Modules/Core/Applicants/ApplicantHelpers";
import {
  IJobFeatures,
  IJobInfoResponse,
  IPostingSourceLinks,
} from "shared/SharedModels";
import { ShButton } from "shared/SharedStyles/ShInputs";
import {
  approveJob,
  generateFeeds,
  getEmployerJob,
  getJobBenchmarkImage,
  getAdminBundles,
} from "store/slices/admin/jobs-reviews-slice";

const JobReview = () => {
  const [tableRows, setTableRows] = useState<IPostingSourceLinks[]>([]);
  const [jobFeatures, setJobFeatures] = useState<IJobFeatures[]>([]);
  const [jobInfo, setJobInfo] = useState<IJobInfoResponse>();

  const dispatch = useAppDispatch();
  const {
    employerJob,
    isFetchingJobs,
    approveJobApiStatus,
    fullFingerPrintImage,
    getFullFingerPrintImageStatus,
    getAdminBundlesStatus,
    jobBundles,
  } = useAppSelector((state) => state.admin.jobReviews);
  const pathname = useLocation().pathname;
  const jobId = pathname.split("/")[3];
  const navigate = useNavigate();

  useEffect(() => {
    const jobInfo = employerJob as IJobInfoResponse;
    setJobInfo(jobInfo);
    setTableRows(jobInfo.postingSourceLinks);
    setJobFeatures(jobInfo.jobFeatures);
  }, [employerJob]);

  useEffect(() => {
    dispatch(getEmployerJob({ jobId }));
  }, [dispatch, jobId]);

  useEffect(() => {
    dispatch(getJobBenchmarkImage({ jobId }));
  }, [dispatch, jobId]);

  useEffect(() => {
    dispatch(getAdminBundles({ jobId }));
  }, [dispatch, jobId]);

  useEffect(() => {
    if (approveJobApiStatus === "success") {
      dispatch(generateFeeds());
      navigate("/admin");
    }
  }, [approveJobApiStatus, navigate, dispatch]);

  useEffect(() => {
    if (getFullFingerPrintImageStatus === "success" && fullFingerPrintImage) {
      renderFingerprintImage(
        fullFingerPrintImage,
        jobId + "full_fingerprint_svg_parent"
      );
    }
  }, [getFullFingerPrintImageStatus, fullFingerPrintImage, jobId]);


  return (
    <Box p={4}>
      <Box>
        <ShAdminTable
          title={"Job Review"}
          headers={AdminJobReviewTableHeaders}
          rows={
            isFetchingJobs === "success" && tableRows?.length
              ? tableRows.map((row) => ({
                  ...row,
                  html: `<a href="${row.link}">Click Here</a>`,
                }))
              : []
          }
          cellOrder={AdminJobReviewCellOrder}
          redirectionUrlsJobId={["link"]}
          redirectionUrlsEmployerId={["link"]}
          generateUrlJobId={(value: string) => value}
          generateUrlEmployerId={(value: string) => value}
          isLoading={isFetchingJobs === "pending"}
        />
      </Box>
      <Box mt={2}>
        <ShAdminTable
          title={"Features"}
          headers={AdminJobFeaturesTableHeaders}
          rows={
            isFetchingJobs === "success" && jobFeatures?.length
              ? jobFeatures
              : []
          }
          cellOrder={AdminJobFeaturesCellOrder}
          isLoading={isFetchingJobs === "pending"}
          redirectionUrlsJobId={[]}
          redirectionUrlsEmployerId={[]}
        />
      </Box>

      <Box mt={2}>
        <ShAdminTable
          title={"Bundles"}
          headers={AdminJobBundlesTableHeaders}
          rows={
            getAdminBundlesStatus === "success" && jobBundles?.length
              ? jobBundles
              : []
          }
          cellOrder={AdminJobBundlesCellOrder}
          isLoading={getAdminBundlesStatus === "pending"}
          redirectionUrlsJobId={[]}
          redirectionUrlsEmployerId={[]}
        />
      </Box>

      <Typography variant="subtitle1" align="left" sx={{ paddingTop: "10px" }}>
        Job Profile
      </Typography>

      <Box
        mt={2}
        sx={{
          backgroundColor: "#F8FBFF",
          display: "flex",
          width: "100%",
          height: "30%",
          borderRadius: "8px",
          padding: "8px",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            width: "35%",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            paddingLeft: "2px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "left",
              alignItems: "left",
              marginTop: "1px",
            }}
          >
            <div
              id={jobId + "full_fingerprint_svg_parent"}
              style={{
                width: "60px",
                height: "auto",
                transform: "scale(0.57)",
                transformOrigin: "left",
              }}
            ></div>
          </Box>
        </Box>
        <Divider
          orientation="vertical"
          flexItem
          sx={{ mx: 2, backgroundColor: "#ccc" }}
        />
        <Box sx={{ flex: 1, paddingLeft: "16px" }}>
          {[
           { label: "Job", value:`${jobInfo?.name} (${jobInfo?.code})`},
            { label: "City", value: jobInfo?.city },
            { label: "Success Profile", value: jobInfo?.sourceJobRole?.name },
            { label: "Employer", value: jobInfo?.employer?.company_name },
            {
              label: "Company Description",
              value: jobInfo?.companydescription,
            },
          ].map((item, index) => (
            <Box
              key={index}
              sx={{ display: "flex", alignItems: "center", mb: 1 }}
            >
              <Typography
                variant="body1"
                sx={{ fontWeight: "bold", width: "40%" }}
              >
                {item.label}:
              </Typography>
              <Typography variant="body2" sx={{ width: "55%" }}>
                {item.value}
              </Typography>
            </Box>
          ))}
        </Box>
      </Box>

      <Box mt={2}>
        {jobInfo && jobInfo.review_state === "pending" && (
          <ShButton
            variant="contained"
            onClick={() => {
              if (jobId) dispatch(approveJob({ jobId: parseInt(jobId) }));
            }}
            disabled={
              isFetchingJobs === "pending" || approveJobApiStatus === "pending"
            }
          >
            <Typography>Approve Job</Typography>
          </ShButton>
        )}
      </Box>
    </Box>
  );
};

export default JobReview;
