import { Box, Stack, Typography } from "@mui/material";
import { IsMdScreen, IsXsScreen } from "helpers/hooks";
import { ResourceTypography } from "Modules/Marketing/Resources/Resources.styled";
import { Link as RouterLink } from "react-router-dom";
import { ShButton } from "shared/SharedStyles/ShInputs";
import { ShContainer } from "shared/SharedStyles/ShLayouts";
import CommonQuestionSidebar from "../CommonQuestionSidebar";

export const Tasks = () => {
  const isMdScreen = IsMdScreen();
  const paddingValue = IsXsScreen() ? 1 : 3;

  return (
    <>
      <Stack padding={paddingValue} direction={"row"}>
        <ShContainer>
          <ResourceTypography variant="h5" fontWeight={"bold"} gutterBottom>
            Create and Manage Tasks
          </ResourceTypography>
          <ResourceTypography variant="body1" paragraph>
            Easily assign and manage tasks for yourself or your team. Stay
            organized and ensure smooth collaboration.
          </ResourceTypography>

          <ResourceTypography paddingTop={2} variant="h6" fontWeight={"bold"} gutterBottom>
            Task Name
          </ResourceTypography>
          <Typography variant="body1" paragraph>
            Give your task a clear and specific title. This makes it easy to identify and
            helps your team understand what needs to be done.
          </Typography>

          <ResourceTypography paddingTop={1} variant="h6" fontWeight={"bold"} gutterBottom>
            Description
          </ResourceTypography>
          <Typography variant="body1" paragraph>
            Provide details about the task, including objectives, steps to follow,
            and any necessary resources.
          </Typography>

          <ResourceTypography paddingTop={1} variant="h6" fontWeight={"bold"} gutterBottom>
            Assign Task
          </ResourceTypography>
          <Typography variant="body1" paragraph>
            Choose who will be responsible for the task. You can assign it to yourself
            or a team member, ensuring the right person is handling the job.
            For collaborative tasks, multiple people can be assigned.
          </Typography>

          <ResourceTypography paddingTop={1} variant="h6" fontWeight={"bold"} gutterBottom>
            Due Date
          </ResourceTypography>
          <Typography variant="body1" paragraph>
            Set a deadline to ensure the task stays on track. Clear due dates help with
            prioritization and time management. Consider adding buffer time if the task is
            complex or dependent on other tasks.
          </Typography>

          <Box paddingTop={2} paddingBottom={3}>
            <ShButton
              component={RouterLink}
              to="/employer/create-task"
              color="primary"
              variant="contained"
            >
              Create Task
            </ShButton>
          </Box>
        </ShContainer>
        {!isMdScreen && <CommonQuestionSidebar />}
      </Stack>
    </>
  );
};

export default Tasks;
