import {
    Alert,
    Box,
    Divider,
    Grid,
    LinearProgress,
    Stack,
    TableBody,
    TableCell,
    TableHead,
    Typography,
} from "@mui/material";
import { IGoogleAuthPayload, IInterviewsDashboard, IInterviewsListParams, InterviewSortCols, InterviewsType } from 'Modules/Core/Interviews/InterviewsModel';

import { InterviewCard } from "Modules/Core/Interviews/InterviewCard";
import { InterviewsFooter } from "Modules/Core/Interviews/InterviewsFooter";
import { InterviewsToolbar } from 'Modules/Core/Interviews/InterviewsToolbar';
import { useNotification } from "Modules/Core/Notification";
import { IsSmScreen, useAppDispatch, useAppSelector } from 'helpers/hooks';
import { useCallback, useEffect, useState } from 'react';
import { useLocation } from "react-router-dom";
import { ISortParamsBase, SortOrder } from 'shared/SharedModels';
import { ShTable, ShTableContainer, ShTableRow } from "shared/SharedStyles/ShDataDisplay";
import { ShAlert } from "shared/SharedStyles/ShFeedback";
import { DefaultPageSize } from "shared/constants";
import { googleUserAuth, resetGoogleUserAuth } from "store/slices/employer/interviews/google-calendar-slice";
import { resetUpdateInterviewSchedule, resetUpdateInterviewStatus } from "store/slices/employer/interviews/interviews-actions-slice";
import { getInterviewsSummary, resetGetInterviewDetails } from "store/slices/employer/interviews/interviews-details-slice";
import { getInterviewsList } from 'store/slices/employer/interviews/interviews-list-slice';

export const Interviews = ({ gridProps, usedInDashboard }: IInterviewsDashboard) => {

    const isSmScreen = IsSmScreen();
    const dispatch = useAppDispatch();
    const notification = useNotification();
    const location = useLocation();
    const calendarCode = new URLSearchParams(location.search)?.get('code');
    const calendarState = new URLSearchParams(location.search)?.get('state');
    const { interviewsSummary } = useAppSelector(state => state.employer.interviews.interviewsDetails);
    const { googleUserAuthStatus, googleUserAuthResponse } = useAppSelector(state => state.employer.interviews.googleCalendar);
    const { interviews, paginationParams, getInterviewsStatus,
        getInterviewsResponse } = useAppSelector(state => state.employer.interviews.interviewsList);
    const { updateInterviewStateStatus, updateInterviewStateResponse } = useAppSelector((state) => state.employer.interviews.interviewsActions);
    const [interviewsType, setInterviewsType] = useState<InterviewsType>('requested');
    const [sortParams, setSortParams] = useState<ISortParamsBase<InterviewSortCols>>({ orderBy: 'recent', order: 'asc' });
    const { updateInterviewScheduleStatus } = useAppSelector((state) => state.employer.interviews.interviewsActions);

    const getInterviews = useCallback((interviewsType: InterviewsType, pageNo?: number, pageSize?: number,
        sortParams?: ISortParamsBase<InterviewSortCols>) => {
        const params: IInterviewsListParams = {
            status: ['Scheduled'],
            pageNo: pageNo ?? 1,
            pageSize: pageSize ?? DefaultPageSize,
            sort: sortParams?.order ?? 'asc',
            sortBy: sortParams?.orderBy ?? 'alphabetical'
        };
        switch (interviewsType) {
            case 'requested':
                params.status = ['Requested'];
                break;
            case 'accepted':
                params.status = ['Accepted'];
                break;
            case 'scheduled':
                params.status = ['Scheduled'];
                break;
            case 'completed':
                params.status = ['Completed'];
                break;
            case 'cancelled':
                params.status = ['Cancelled'];
                break;
        }
        dispatch(getInterviewsList(params))
    }, [dispatch]);

    useEffect(() => {
        if (interviews?.length === 0) {
            getInterviews(interviewsType);
        } else if (usedInDashboard) {
            getInterviews('requested');
        }
    }, [getInterviews, interviews?.length, interviewsType, usedInDashboard]);

    // auth google calendar
    useEffect(() => {
        if (calendarCode && calendarState) {
            const payload: IGoogleAuthPayload = { code: calendarCode, state: calendarState }
            dispatch(googleUserAuth({ payload }));
        }
    }, [calendarCode, calendarState, dispatch]);

    useEffect(() => {
        if (googleUserAuthStatus === 'success') {
            getInterviews('scheduled');
        } else if (googleUserAuthStatus === 'failed') {
            notification.displayNotification({
                open: true,
                type: 'error',
                message: googleUserAuthResponse as string
            });
            dispatch(resetGoogleUserAuth());
        }
    }, [dispatch, getInterviews, googleUserAuthResponse, googleUserAuthStatus, notification]);

    // Get interviews summary on page load.
    useEffect(() => {
        if (!interviewsSummary) {
            dispatch(getInterviewsSummary());
        }
    }, [dispatch, interviewsSummary]);


    useEffect(() => {
        // clear on unmount
        return () => {
            dispatch(resetGetInterviewDetails());
        }
    }, [dispatch]);

    // Refresh list on interview status updates.
    useEffect(() => {
        if (updateInterviewStateStatus === 'success') {
            dispatch(getInterviewsSummary());
            getInterviews(interviewsType);
            dispatch(resetUpdateInterviewStatus());
        } else if (updateInterviewStateStatus === 'failed') {
            notification.displayNotification({
                open: true,
                type: 'error',
                message: updateInterviewStateResponse
            });
            dispatch(resetUpdateInterviewStatus());
        }
    }, [dispatch, getInterviews, interviewsType, notification, updateInterviewStateResponse, updateInterviewStateStatus]);

    const sortList = (sortBy: InterviewSortCols, order: SortOrder) => {
        setSortParams({ order: order, orderBy: sortBy });
        getInterviews(interviewsType, paginationParams.requestedPageNumber,
            paginationParams.requestedPageSize, { order: order, orderBy: sortBy });
    };

    // Refresh list if any interview is updated.
    useEffect(() => {
        if (updateInterviewScheduleStatus === 'success') {
            getInterviews(interviewsType);
            // Reset state once interview is updated.
            dispatch(resetUpdateInterviewSchedule());

        }
    }, [dispatch, getInterviews, interviewsType, updateInterviewScheduleStatus]);

    return (<>
        <Stack padding={usedInDashboard ? 0 : 1} height='100%' minHeight={200} width='100%'>
            {!usedInDashboard && interviewsSummary && <>
                <InterviewsToolbar interviewsType={interviewsType} setInterviewsType={setInterviewsType}
                    interviewsCount={interviewsSummary} sortList={sortList} sortParams={sortParams}
                    getInterviews={getInterviews} />
            </>}
            <Box flex={1} overflow='auto' paddingRight={1} paddingY={1}>
                {getInterviewsStatus === 'pending' && <LinearProgress />}
                {getInterviewsStatus === 'failed' && <Alert title={getInterviewsResponse} severity="error" />}
                {usedInDashboard && getInterviewsStatus === 'success' && interviews?.length === 0 && <Alert severity="info">No interviews found.</Alert>}

                <Grid container spacing={2} justifyItems='self-start' >
                    {usedInDashboard ? (
                        interviews.map((interview, index) => (
                            <Grid item {...gridProps || { xs: 12, sm: 12, md: 6, lg: 4, xl: 3 }} height='fit-content'>
                                <InterviewCard interview={interview} usedInDashboard={usedInDashboard} key={index} />
                            </Grid>
                        ))
                    ) : (
                        <>
                            {getInterviewsStatus === 'success' && interviews?.length === 0 ? (
                                <Stack spacing={0} alignSelf='center' margin='auto' width='100%'>
                                    <ShAlert severity="info">
                                        No interviews found.
                                    </ShAlert>
                                </Stack>
                            ) : (
                                    <ShTableContainer>     
                                        <Box padding={1}>                                
                                            <ShTable>
                                                <TableHead>
                                                    <ShTableRow cursor="default">
                                                    <TableCell>
                                                        <Typography variant="caption">Name</Typography>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Typography variant="caption">Status</Typography>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Typography variant="caption">Job Title</Typography>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Typography variant="caption">Interview type</Typography>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Typography variant="caption">Date {interviewsType}</Typography>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Typography variant="caption">Actions</Typography>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Typography variant="caption">View Profile</Typography>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Typography variant="caption">View Interview Kits</Typography>
                                                    </TableCell>
                                                    </ShTableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {interviews.map((interview, index) => (
                                                        <ShTableRow key={`${index}-${interview.id}-${interview.created_at}`} cursor="default">
                                                            <InterviewCard interview={interview} usedInDashboard={usedInDashboard} />
                                                        </ShTableRow>
                                                    ))}
                                                </TableBody>
                                            </ShTable>
                                        </Box>
                                    </ShTableContainer>
                             
                            )}
                        </>
                    )}
                </Grid>
            </Box>
            {!usedInDashboard && <>
                <Divider />
                <InterviewsFooter getInterviews={getInterviews} interviewsType={interviewsType} isSmScreen={isSmScreen}
                    paginationParams={paginationParams} />
            </>}
        </Stack>
    </>);
};